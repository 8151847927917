export const categoryData = [
  {
    _id: '652fade27d7587e70af694f5',
    code: '6',
    name: 'Premium',
    type: 'Entreprise',
    period: '200',
    price: '500',
    rmv_bg_quantity: 200,
    category: '100',
    photo: null,
    createDate: '2023-10-18T10:05:22.126Z',
    updateDate: '2023-10-20T13:23:46.404Z',
    __v: 0,
  },
]
