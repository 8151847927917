// import {useQuery} from 'react-query'
// import {isNotEmpty, QUERIES} from '../../../../../components/helpers'
import {useListView} from '../core/ListViewProvider'
// import {getUserById} from '../core/_requests'
import {useAppDispatch} from '../../../../../hooks'
import {getOneFunctionById} from '../../../../../redux/actions/commonCrud'
import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../redux/actionTypes'
import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import { NotificationEditModalForm } from './NotificationEditModalForm'
import { ConfigState, INotification } from '../../../../../type'
// import {fetchStart, fetchSuccess} from '../../../../redux/actions/common'
const NotificationEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const oneNotification: INotification = useSelector(({config}: {config: ConfigState}) => {
    return config.oneNotification
  })
  const [loading, setLoading] = useState(false)
  const data = useMemo(() => oneNotification, [oneNotification])
  const dispatch = useAppDispatch()
  useEffect(() => {
    setLoading(true)
    if (itemIdForUpdate) {
  
    }
  }, [dispatch, itemIdForUpdate])

  if (!itemIdForUpdate) {
    return <NotificationEditModalForm  />
  }

  if (data) {
    return <NotificationEditModalForm />
  }

  return null
}

export default NotificationEditModalFormWrapper
