import {ListViewProvider} from './core/ListViewProvider'
import {UsersListHeader} from './components/header/RPListHeader'
import {AllUsersActivitiesTable} from './table/AllUsersActivitiesTable'
// import {UserEditModal} from './relayPoints-edit-modal/UserEditModal'
// import {KTCard} from '../../../../_template/helpers'
import InfoView from '../../../../_template/layout/components/infoView/index'
import {getAllFunction} from '../../../redux/actions/commonCrud'
import {useEffect} from 'react'
import {useAppDispatch} from '../../../hooks'
import * as endpoints from '../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../redux/actionTypes'
import {PageTitle} from '../../../../_template/layout/core'
import {useIntl} from 'react-intl'
import getBreadCrambs from '../../../routing/breadcramps/getBreadCrambs'

const AllUsersActivitiesList = () => {
  const intl = useIntl()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getAllFunction(endpoints.userEndpoint, actionTypes.GET_ALL_USERS, {per_page:100
    }))
    dispatch(getAllFunction(endpoints.Actions, actionTypes.GET_ALL_ACTIONS, {per_page:100}))
    dispatch(
      getAllFunction(endpoints.userActivitiesEndpoint, actionTypes.GET_ALL_USER_ACTIVITIES, {
        page: 1,
      })
    )

    return () => {
      dispatch({type: actionTypes.GET_ALL_USERS, payload: {data: [], totalPages: 1}})
      dispatch({type: actionTypes.GET_ALL_ACTIONS, payload: {data: [], totalPages: 1}})
      dispatch({type: actionTypes.GET_ALL_USER_ACTIVITIES, payload: {data: [], totalPages: 1}})
    }
  }, [dispatch])
  return (
    <>
      <div
        className='d-flex justify-content-between'
        // style={{marginTop: '-4%', marginBottom: '25px'}}
      >
        <div>
          <PageTitle breadcrumbs={getBreadCrambs(intl)}>
            {intl.formatMessage({id: 'MENU.USER.ACTIVITIES'})}
          </PageTitle>
          <InfoView />
        </div>
        {/* <div>
          <UsersListHeader />
        </div> */}
      </div>

      <AllUsersActivitiesTable />
      
    </>
  )
}

const AllUsersActivitiesWrapper = () => (
  <ListViewProvider>
    <AllUsersActivitiesList />
  </ListViewProvider>
)

export {AllUsersActivitiesWrapper}
