import { AxiosResponse } from 'axios'
import * as actionTypes from '../actionTypes'
import dataAxios from '../../modules/auth/core/dataAxios'
import { Action, Dispatch } from '@reduxjs/toolkit'

import * as responseCodes from '../../../_template/helpers/responseCodes'
import { UsersQueryResponse } from '../../pages/ListUsers/users-list/core/_models'

export const getUserByCountry = (endpoint: string, actionType: string, filters: any) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const res: AxiosResponse<UsersQueryResponse> = await dataAxios.get(endpoint, {
        params: { ...filters },
      })
      if (res.data?.code === responseCodes.NO_DATA_FOUND) {
        dispatch({
          type: actionType,
          payload: { data: [], totalPages: 1 },
        })
      } else {
        dispatch({
          type: actionType,
          payload: res.data,
        })
      }
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_ERROR,
        payload: '',
      })
    }
  }
}