import {useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import * as actionTypes from '../../../../redux/actionTypes'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import {usersColumns} from './columns/_columns'
// import {RelayPoint} from '../core/_models'
import {KTCard, KTCardBody} from '../../../../../_template/helpers'
import {useSelector} from 'react-redux'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {RPListSearchComponent} from '../components/header/RPFiltersComponent'
import {useAppDispatch} from '../../../../hooks'
import {getAllFunction} from '../../../../redux/actions/commonCrud'
import PaginationComponent from '../../../../../_template/layout/components/pagination'
import {IAllUsersActivities, UsersState} from '../../../../type'
import DocsCount from '../../../../components/DocsCount'
import { useIntl } from 'react-intl'
type Props = {
  eventKey?: string | undefined
}
type values = {
  role?: string | undefined
  phone?: string | undefined
  status?: string | undefined
  firstname?: string | undefined
  lastname?: string | undefined
  email?: string | undefined
}
const AllUsersActivitiesTable: React.FC<Props> = ({eventKey}) => {
  const relayPoint: IAllUsersActivities[] = useSelector(({users}: {users: UsersState}) => {
    return users.uploadActivities
  })
  const relayPointe: IAllUsersActivities[] = useSelector(({users}: {users: UsersState}) => {
    return users.uploadActivities
  })
  const totalPages: number = useSelector(({users}: {users: UsersState}) => {
    return users.totalPages
  })
  const totalitems: number = useSelector(({users}: {users: UsersState}) => {
    return users.totalItems
  })
  const [values, setValues] = useState<values | undefined>({
    status: eventKey === 'All' ? undefined : eventKey,
  })
  const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }
  const intl=useIntl()
  // const isLoading = useQueryResponseLoading()
  const data: IAllUsersActivities[] = useMemo(() => relayPoint, [relayPoint])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const dispatch = useAppDispatch()
  const handleDispatch = () => {
    dispatch(
      getAllFunction(endpoints.userActivitiesEndpoint, actionTypes.GET_ALL_USER_ACTIVITIES, values)
    )
  }

  return (
    <KTCard>
      <KTCardBody className='py-4'>
        <div className='w-md-100'>
          <RPListSearchComponent
            values={values}
            handleChangeValues={handleChangeValues}
            handleDispatch={handleDispatch}
            eventKey={eventKey}
          />
        </div>
        <div className='d-flex' style={{ marginTop: "-2.8rem" }}>
          <DocsCount
            showNumbers={true}
            count={totalitems}

            title={intl.formatMessage({ id: "MENU.USER.ACTIVITIES" })}
          />
        </div>
        <div className='table-responsive' style={{overflow: 'hidden'}}>
          <table
            id='kt_table_users'
            className='table align-middle fs-6 gy-5 dataTable no-footer  '
            {...getTableProps()}
          >
            <thead style={{backgroundColor: '#EFF0F4'}}>
              <tr className='text-bluee fw-bolder fs-7 text-uppercase gs-0 '>
                {headers.map((column: ColumnInstance<IAllUsersActivities>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-500 fs-7' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IAllUsersActivities>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <PaginationComponent
          values={values}
          setValues={setValues}
          endpoint={endpoints.userActivitiesEndpoint}
          actionType={actionTypes.GET_ALL_USER_ACTIVITIES}
          totalPages={totalPages}
        />
        {/* {isLoading && <UsersListLoading />} */}
      </KTCardBody>
    </KTCard>
  )
}

export {AllUsersActivitiesTable}
