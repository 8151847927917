import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_template/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_template/assets/ts/_utils'
import {WithChildren} from '../../_template/helpers'
// import BuilderPageWrapper from '../pages/ListUsers/UsersPageWrapper'
// import SubsctiptionsInfo from '../pages/ListOrders/orders-list/order-info/ordersInfo'
import UsersList from '../pages/ListUsers/UsersPageWrapper'
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import RelayPointsPageWrapper from '../pages/AllUsersActivities/AllUsersActivitiesPageWrapper'
import {UserActivityInfo} from '../pages/AllUsersActivities/AllUsersActivities-list/AllUsersActivities-info/AllUsersActivitiesInfo'
import {UsersInfo} from '../pages/ListUsers/users-list/users-info/UsersInfo'

// import DeliveryTypePageWrapper from ''
import {SubscriptionPlanInfo} from '../pages/configuration/ListSubscriptionPlan/subscription-plan-list/subscription-plan-info/Subscription-planInfo'

import DriversPageWrapper from '../pages/ActivitiesByOneUser/UserActivitiesPageWrapper'
import {Notifications} from '../modules/accounts/components/settings/cards/Notifications'
import {NotificationEditModalForm} from '../pages/configuration/notifications/notifications-list/notification-edit-modal/NotificationEditModalForm'
import NotificationPageWrapper from '../pages/configuration/notifications/notificationsPageWrapper'
import DriverAreaPageWrapper from '../pages/configuration/Actions/ActionsPageWrapper'
import DriverAreaView from '../pages/configuration/Actions/Actions-list/ActionsView'
import SubscriptionsPageWrapper from '../pages/ListSubscriptions/SubscriptionsPageWrapper'
import SubscriptionsDetailsTabs from '../pages/ListSubscriptions/subscriptions-list/Subscriptions-info/tab'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const DeliveryTypePageWrapper = lazy(
    () => import('../pages/configuration/ListSubscriptionPlan/subscriptionPlanPageWrapper')
  )

  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='upload-report' element={<RelayPointsPageWrapper />} />

        <Route path='subscriptions/:id' element={<SubscriptionsDetailsTabs />} />

        <Route path='upload-report/:id' element={<UserActivityInfo />} />

        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}

        <Route
          path='users/:id'
          element={
            <SuspensedView>
              <UsersInfo />
            </SuspensedView>
          }
        />
        <Route
          path='users'
          element={
            <SuspensedView>
              <UsersList />
            </SuspensedView>
          }
        />
        <Route
          path='/users/:id/activities'
          element={
            <SuspensedView>
              <DriversPageWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='subscriptions'
          element={
            <SuspensedView>
              <SubscriptionsPageWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='configutation/categories'
          element={
            <SuspensedView>
              <DeliveryTypePageWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='configutation/categories/:id'
          element={
            <SuspensedView>
              <SubscriptionPlanInfo />
            </SuspensedView>
          }
        />
        <Route
          path='configutation/notification'
          element={
            <SuspensedView>
              <NotificationPageWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='configutation/actions'
          element={
            <SuspensedView>
              <DriverAreaPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='configutation/actions/view/:id' element={<DriverAreaView />} />

        {/* <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
