/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../../hooks'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'

import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../../../_template/layout/core'
import Loading from '../../../../../_template/layout/components/loading/Loading'
import {toAbsoluteUrl} from '../../../../../_template/helpers'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'
import {getOneFunctionById} from '../../../../redux/actions/commonCrud'
import {StatisticsDriver} from '../../../ListUsers/users-list/users-info/statisticsDriver'
import {IAllUsersActivities, UsersState} from '../../../../type'
import {StatisticsWidget2} from '../../../dashboard/InfoBarWidget2'

export function UserActivityInfo() {
  const {id} = useParams()
  const intl = useIntl()
  const userActivity: IAllUsersActivities = useSelector(({users}: {users: UsersState}) => {
    return users.oneUploadActivity
  })
  console.log('****' + JSON.stringify(userActivity))
  const UserActivitiesQuota: any = useSelector(({users}: {users: UsersState}) => {
    return users.UserActivitiesQuota
  })

  const blankImg = toAbsoluteUrl('/media/avatars/png-avatar.png')
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  useEffect(() => {
    setLoading(true)
    dispatch(
      getOneFunctionById(
        endpoints.userActivitiesEndpoint,
        actionTypes.GET_ONE_USER_ACTIVITY_BY_ID,
        id
      )
    )

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [dispatch])

  return (
    <>
      {loading && <Loading />}
      {/* <InfoView /> */}
      <div className='d-flex justify-content-between'>
        <PageTitle breadcrumbs={getBreadCrambs(intl)}>
          {intl.formatMessage({id: 'GORELLA.USER_ACTIVITIES.DETAILS'})}
        </PageTitle>
      </div>
      {userActivity && (
        <div className='vstack gap-10 bg-white rounded p-10'>
          <div className='d-flex flex-column flex-sm-row gap-12'>
            {/* begin::Image input */}
            <div
              className='image-input image-input-outline mx-auto'
              data-kt-image-input='true'
              style={{
                backgroundImage: `url('${blankImg}')`,
                padding: '10px',
                width: 'fit-content',
                borderColor: '#c6c9cc42',
                borderStyle: 'dashed',
                borderRadius: '70px',
              }}
            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-110px h-110px p-5'
                style={{
                  borderRadius: '70px',
                  backgroundImage: `url('${
                    process.env.REACT_APP_API_URL + '/' + userActivity?.user_info?.avatar
                  }')`,
                }}
              ></div>
              {/* end::Preview existing avatar */}
              {/* begin::Cancel */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Cancel */}
            </div>
            {/* end::Image input */}

            <div className='my-auto vstack gap-2 mx-auto'>
              <span className='fs-3 fw-bold text-bluee'>
                {' '}
                {userActivity?.user_info?.firstname + ' ' + userActivity?.user_info?.lastname}{' '}
              </span>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'GORELLA.COMMON.COUNTRY'})}
                value={userActivity?.user_info?.country}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'GORELLA.COMMON.TYPE'})}
                value={userActivity?.user_info?.type}
              />
            </div>
            <div style={{paddingRight: '40rem', marginTop: '3.6rem'}}>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'GORELLA.COMMON.SUBSCRIPTIONS'})}
                value={userActivity?.user_info?.subscription?.subscription_type_name}
              />
              <div style={{paddingTop: '0.5rem'}}>
                <HorizontalAttributeComponent
                  attr={intl.formatMessage({id: 'GORELLA.COMMON.ACTIONS'})}
                  value={userActivity?.action_info?.name}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='d-flex bg-white p-5 mt-4  rounded  justify-content-between  '>
        <div className='w-auto mt-8 p-10'>
          <div className='row g-5 g-xl-8'>
            <h4>
              <FormattedMessage id='GORELLA.COMMON.SUBSCRIPTION_QUOTA' />
            </h4>
            {userActivity?.user_info?.actions
              ? userActivity?.user_info?.actions?.map((item: any, i: number) => (
                  <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-4 my-2' key={`statInfo` + i}>
                    <StatisticsWidget2
                      className='card-xl-stretch mb-xl-8'
                      avatar={''}
                      title={item.name}
                      value={item.quantity}
                      percentage={item.percentage}
                    />
                  </div>
                ))
              : userActivity?.user_info?.subscription?.actions?.map((item: any, i: number) => (
                  <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-4 my-2' key={`statInfo` + i}>
                    <StatisticsWidget2
                      className='card-xl-stretch mb-xl-8'
                      avatar={''}
                      title={item.name}
                      value={item.quantity}
                      percentage={item.percentage}
                    />
                  </div>
                ))}
          </div>
        </div>
      </div>
    </>
  )
}

const HorizontalAttributeComponent = ({
  attr = 'Test',
  value = 'Test',
}: {
  attr?: string
  value?: string
}) => (
  <div className='d-flex   fs-6 my-auto'>
    <div className='text-darkblue fw-bold'>{attr}</div> :{' '}
    <div className='mx-8 text-bluePlaceholder'> {value}</div>
  </div>
)
