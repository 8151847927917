/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../../hooks'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import {UsersListLoading} from '../../../ListUsers/users-list/components/loading/UsersListLoading'
import {useParams} from 'react-router-dom'
import DetailsBox from './detailsBox'
import {getOneFunctionById} from '../../../../redux/actions/commonCrud'
import { ActivitiesState, IUserSubscription } from '../../../../type'
import { KTCard, KTCardBody } from '../../../../../_template/helpers'
import { useTable } from 'react-table'

type Props = {
  className: string
}
const SubsctiptionsInfo: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const subscription: IUserSubscription = useSelector(
    ({activities}: {activities: ActivitiesState}) => activities.OneUserSubscription
  )

  let {id} = useParams()
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  useEffect(() => {
    setLoading(true)
    dispatch(
      getOneFunctionById(
        endpoints.listUsersSubscriptions,
        actionTypes.GET_ONE_USER_SUBSCRIPTION,
        id
      )
    )

    setTimeout(() => {
      setLoading(false)
    }, 100000)

    return () => {
      //dispatch({type: actionTypes.GET_ONE_USER_SUBSCRIPTION, payload: {}})
    }
    
  }, [dispatch, id])

  return (
    <>
      {/* <div>
        <div
          style={{backgroundColor: '#e4e6ef30'}}
          className='card mb-5 mb-xl-10'
          id='kt_profile_details_view'
        > */}
        
              
                {/* <TablesWidget9 columns={columns} className='mb-5 mb-xl-8' data={[order?.user]} /> */}
                {/* <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 p-5'>
                  <DetailsBox
                    rows={[
                      {
                        code: 'NAME',
                        title: intl.formatMessage({id: 'GORELLA.COMMON.NAME'}),
                        icon: 'bi-bag-fill',
                        value: `${subscription.subscription_type_name}`,
                      },
                      {code:'', title: 'shipping method', icon: 'bi-truck', value: '30/05'},
                      {
                        code: '',
                        title: intl.formatMessage({id: 'GORELLA.COMMON.PRICE'}),
                        icon: 'bi-truck',
                        value: `${
                          subscription.totalAmount &&
                          parseFloat(subscription.totalAmount)?.toFixed(3) + ' TND'
                        }`,
                      },
                      {
                        code: '',
                        title: intl.formatMessage({id: 'GORELLA.COMMON.CREATIONDATE'}),
                        icon: 'bi-upc',
                        value: `${subscription.start_date}`,
                      },
                      {
                        code: '',
                        title: intl.formatMessage({id: 'GORELLA.COMMON.EXPIRYDATE'}),
                        icon: 'bi-upc',
                        value: `${subscription.end_date}`,
                      },
                    ]}
                    className={''}
                    title={`${intl.formatMessage({id: 'GORELLA.COMMON.SUBSCRIPTIONS'})} `}
                  />
                </div> */}
                <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 p-5'>
                  {/* <DetailsBox
                    rows={[
                      {
                        code: 'user',
                        title: intl.formatMessage({id: 'GORELLA.COMMON.USER'}),
                        icon: 'bi-person-circle',
                        value: `${
                          subscription.user?.firstname + ' ' + subscription.user?.lastname
                        }`,
                      },
                      {
                        code: 'EMAIL',
                        title: intl.formatMessage({id: 'GORELLA.COMMON.EMAIL'}),
                        icon: 'bi-envelope-at-fill',
                        value: `${subscription.user?.email}`,
                      },
                      {
                        code: '',
                        title: intl.formatMessage({id: 'GORELLA.COMMON.PHONENUMBER'}),
                        icon: 'bi-phone-fill',
                        value: `${subscription.user?.phoneNumber}`,
                      },
                      {
                        code: '',
                        title: intl.formatMessage({id: 'GORELLA.COMMON.COUNTRY'}),
                        icon: 'bi-phone-fill',
                        value: `${subscription.user?.address?.country}`,
                      },
                    ]}
                    className={''}
                    title={intl.formatMessage({id: 'GORELLA.USERS.USERS_DETAILS'})}
                  /> */}
                </div>
                <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 p-5'>
                  {
                    //subscription && (
                    // <DetailsBox
                    //   rows={[
                    //     {
                    //       code: '',
                    //       title: intl.formatMessage({id: 'GORELLA.COMMON.PAYMENT&SHIPING'}),
                    //       icon: 'bi-cash-coin',
                    //       value: `${
                    //         subscription.paidThrough === 'mobicash' ||
                    //         subscription.status?.code === '100'
                    //           ? intl.formatMessage({id: 'GORELLA.SUBSCRIPTION.PAID'})
                    //           : intl.formatMessage({id: 'GORELLA.SUBSCRIPTION.FREE'})
                    //       }`,
                    //     },
                    //     {
                    //       code: '',
                    //       title: intl.formatMessage({id: 'GORELLA.COMMON.PAYMENTMETHOD'}),
                    //       icon: 'bi-wallet-fill',
                    //       value: `${subscription.paidThrough}`,
                    //     },
                    //     {
                    //       code: '',
                    //       title: intl.formatMessage({id: 'GORELLA.COMMON.QUOTA'}),
                    //       icon: 'bi-wallet-fill',
                    //       value: `${subscription.rmv_bg_quantity}`,
                    //     },
                    //     {
                    //       code: '',
                    //       title: intl.formatMessage({id: 'GORELLA.COMMON.QUOTA_AVAILABLE'}),
                    //       icon: 'bi-wallet-fill',
                    //       value: `${subscription.availableQuota}`,
                    //     },
                    //     // {
                    //     //   code: '',
                    //     //   title: intl.formatMessage({id: 'GORELLA.COMMON.SHIPPING'}),
                    //     //   icon: 'bi-truck',
                    //     //   value: '30/05',
                    //     // },
                    //   ]}
                    //   className={''}
                    //   title={intl.formatMessage({id: 'GORELLA.COMMON.CATEGORY_DETAILS'})}
                    // />
                    //  )
                  }
                </div>
             
           
        {/* </div>
      </div> */}
      <div style={{marginTop:"-4.5rem"}}>
     <div className='py-3 mx-8 text-muted fs-7'>  {intl.formatMessage({id: 'GORELLA.COMMON.SUBSCRIPTION_DETAILS'})}</div>
     
      <KTCard >
      <KTCardBody className='py-0' >
        <div className='table-responsive' style={{marginBottom:"3rem"}}>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
           
          >
            <thead style={{backgroundColor:"#EFF0F4"}}>
              <tr className='text-bluee fw-bolder fs-7 text-uppercase gs-0'>
               <td> {intl.formatMessage({id: 'GORELLA.COMMON.NAME'})} </td>
               <td> {intl.formatMessage({id: 'GORELLA.COMMON.PRICE'})} </td>
               <td>{ intl.formatMessage({id: 'GORELLA.COMMON.CREATIONDATE'})}</td>
               <td>{intl.formatMessage({id: 'GORELLA.COMMON.EXPIRYDATE'})}</td>
              </tr>
            </thead>
            <tbody className='text-gray-500 fs-6'>
              <td> {`${subscription.subscription_type_name}`}</td>
              <td> {`${subscription.subscription_type_price}`}</td>
              <td> {`${subscription.start_date}`}</td>
              <td> {`${subscription.end_date}`}</td>

            </tbody>
          </table>
        </div>
      </KTCardBody>
    </KTCard>
    </div>
    </>
  )
}
export default SubsctiptionsInfo

