/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, FC, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, useDebounce} from '../../../../../../_template/helpers'
import {useLang} from '../../../../../../_template/i18n/Metronici18n'
import {useAppDispatch} from '../../../../../hooks'
import {getAllFunction} from '../../../../../redux/actions/commonCrud'
import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../redux/actionTypes'
import {useSelector} from 'react-redux'
import {Typeahead} from 'react-bootstrap-typeahead'
import {useRef} from 'react'
import {ConfigState, ISubscriptionpLAN, IUsers, UsersState} from '../../../../../type'
const TypeaheadInput: React.FC<TypeaheadInputProps> = ({
  options,
  onChange,
  placeholder,
  ref,
  selected,
  setSelected,
}) => {
  //const [selected, setSelected] = useState<Option[]>([])

  const handleOnChange = (selected: any[]) => {
    setSelected(selected)
    onChange(selected)
  }
  return (
    <Typeahead
      ref={ref}
      id='basic-typeahead-customer'
      data-kt-menu-dismiss='false'
      labelKey='name'
      options={options}
      onChange={handleOnChange}
      selected={selected}
      placeholder={placeholder}
      style={{maxWidth:'100%'}}
      className='form-control-solid '

     
    />
  )
}
const SubscriptionsSearch: FC<Props> = ({
  values,
  // eventKey,
  handleChangeValues,
  handleDispatch,
  setFiltersValues,
  filtersValues,
}) => {
  const locale: string = useLang()
  const intl = useIntl()
  const dispatch = useAppDispatch()
  // const customersList: ICustomer[] = useSelector(
  //   ({users}: {users: UsersState}) => users.customers
  // ).map((item) => ({
  //   id: item._id,
  //   name: item.firstname + ' ' + item.lastname,
  // }))
  // const sellersList: ISeller[] = useSelector(({users}: {users: UsersState}) => users.sellers).map(
  //   (item) => ({
  //     id: item._id,
  //     name: item.shopName,
  //   })
  // )
  // const driversList: IUsers[] = useSelector(({users}: {users: UsersState}) => users.usersList).map(
  //   (item) => ({
  //     id: item._id,
  //     name: item.firstname + ' ' + item.lastname,
  //   })
  // )
  const subscriptionCategory: ISubscriptionpLAN[] = useSelector(
    ({config}: {config: ConfigState}) => {
      return config.subscriptionCategory
    }
  )?.map((item) => ({
    id: item._id,
    name: item.name,
  }))

  const typeaheadRefs = [useRef<any>(null), useRef<any>(null), useRef<any>(null)]

  const [selected, setSelected] = useState<Option[]>([])
  const [selected1, setSelected1] = useState<Option[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')

  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        dispatch(
          getAllFunction(endpoints.listUsersSubscriptions, actionTypes.GET_ALL_USER_SUBSCRIPTIONS, {
            searchOrder: searchTerm,
          })
        )
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  const handleUserChange = (selected: Option[]) => {
    setSelected(selected)
    setFiltersValues((prevState: any) => ({
      ...prevState,
      user_id: selected[0] && selected[0]?.id,
    }))
  }

  const handleSubscriptionsChange = (selected: Option[]) => {
    setFiltersValues((prevState: any) => ({
      ...prevState,
      subscription_type_id: selected[0] && selected[0]?.id,
    }))
  }

  const handleReset = () => {
    typeaheadRefs.forEach((ref) => {
      ref.current?.clear()
    })
    setSelected([])
    setSelected1([])

    setFiltersValues((prevState: any) => ({
      ...prevState,
      user: undefined,
      shippingMethods: undefined,
      status: undefined,
      user_id: [],
    }))
  }
  const users: IUsers[] = useSelector(({users}: {users: UsersState}) => {
    return users.usersList
  })?.map((item) => ({
    id: item._id,
    name: item.firstname + ' ' + item.lastname,
  }))
  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex'>
        <div
          style={{
            position: 'relative',
          }}
          className='d-flex align-items-center position-relative my-6 w-100'
        >
          {' '}
          <button
            style={{
              position: 'absolute',
              right: locale !== 'ar' ? 0 : undefined,
              left: locale === 'ar' ? 0 : undefined,
              border: 0,
              zIndex: 5,
            }}
            data-kt-menu-height='auto'
            data-kt-menu-static='true'
            data-kt-menu-trigger='click'
            data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
            type='button'
            className='btn btn-icon'
          >
            <img src='/media/icons/duotune/print/Filter.png' className='position-absolute' />{' '}
          </button>
          {/* begin::SubMenu */}
          <div
            className='menu menu-sub menu-sub-dropdown w-300px w-md-325px overflow-auto'
            data-kt-menu='true'
            style={{maxHeight: 'fit-content'}}
          >
            {/* begin::Header */}
            <div className='px-7 py-5'>
              <div className='fs-7 text-dark fw-bolder'>
                {intl.formatMessage({id: 'GORELLA.COMMON.FILTER_OPTIONS'})}
              </div>
            </div>
            {/* end::Header */}

            {/* begin::Separator */}
            <div className='d-flex justify-content-center'>
              <div className='separator border-gray-200' style={{width: '280px'}}></div>
            </div>
            {/* end::Separator */}

            {/* begin::Content */}
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              {/* begin::Input group */}
              {/* end::Input group */}
              <div className='mb-10'>
                <label className='form-label text-darkblue fs-8 fw-bold'>
                  {intl.formatMessage({id: 'GORELLA.COMMON.USER'})}
                </label>

                <TypeaheadInput
                
                  ref={typeaheadRefs[0]}
                  options={users}
                  onChange={handleUserChange}
                  selected={selected}
                  setSelected={setSelected}
                  placeholder={intl.formatMessage({id: 'GORELLA.SELECT.USER'})}
                 
                />
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label text-darkblue fs-8 fw-bold'>
                  {' '}
                  {intl.formatMessage({id: 'GORELLA.COMMON.SUBSCRIPTIONS'})}
                </label>
                <TypeaheadInput
                  ref={typeaheadRefs[1]}
                  options={subscriptionCategory}
                  onChange={handleSubscriptionsChange}
                  selected={selected1}
                  setSelected={setSelected1}
                  placeholder={intl.formatMessage({id: 'GORELLA.SELECT.SUBSCRIPTIONS'})}
                  
                />
                
              </div>
              {/* end::Input group */}

              {/* begin::Actions */}
              <div className='d-flex justify-content-between'>
                <button
                  // disabled={isLoading}
                  type='button'
                  onClick={() => handleReset()}
                  className='btn btn-light fw-bold px-6'
                  data-kt-menu-dismiss='false'
                  data-kt-user-table-filter='filter'
                >
                  {intl.formatMessage({id: 'GORELLA.COMMON.RESET'})}
                </button>
                <button
                  // disabled={isLoading}
                  type='button'
                  onClick={() => handleDispatch()}
                  className='btn btn-design-blue btn-active-light-primary'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                >
                  {intl.formatMessage({id: 'GORELLA.COMMON.APPLY'})}
                </button>
              </div>

              {/* end::Actions */}
            </div>
            {/* end::Content */}
          </div>
          {/* end::SubMenu */}
        </div>
        {/* end::Search */}
      </div>
    </div>
  )
}

const OrdersListSearchComponent: FC<Props> = ({
  values,
  // eventKey,
  handleChangeValues,
  handleDispatch,
  setFiltersValues,
  filtersValues,
}) => (
  <>
    <SubscriptionsSearch
      values={values}
      //eventKey={eventKey}
      handleChangeValues={handleChangeValues}
      handleDispatch={handleDispatch}
      setFiltersValues={setFiltersValues}
      filtersValues={filtersValues}
    />
  </>
)

export {OrdersListSearchComponent}

type values = {
  role?: string | undefined
  phone?: string | undefined
  status?: string | undefined
  firstname?: string | undefined
  lastname?: string | undefined
  email?: string | undefined
}
type Props = {
  //eventKey: string | undefined
  handleDispatch: () => any
  values: values | undefined
  handleChangeValues: (e: any) => any
  setFiltersValues: (e: any) => any
  filtersValues: any
}
type Option = {name: string; id: string}
type TypeaheadInputProps = {
  options: any[]
  onChange: (selected: any[]) => void
  placeholder?: string
  ref: any
  selected: Option[]
  setSelected: any
}
