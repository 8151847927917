/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
import {FC} from 'react'
import {IUsers} from '../../../../app/type'
// import {toAbsoluteUrl} from '../../../../../../components/helpers'
// import {User} from '../../core/_models'

type Props = {
  user: IUsers
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center '>
    {/* begin:: Avatar */}
    <img
      src={process.env.REACT_APP_API_URL + '/' + user?.avatar}
      alt='Avatar'
      className='rounded-circle me-2'
      style={{width: '40px', height: '40px'}}
    />

    <div className='d-flex flex-column fs-8 text-darkblue fw-bold' style={{color: '#3F434A'}}>
      {/* <a href='#' className='text-gray-800 text-hover-primary mb-1'> */}
      {user?.firstname} {user?.lastname}
      {/* </a> */}
      {/* <span>{user.email}</span> */}
    </div>
  </div>
)

export {UserInfoCell}
