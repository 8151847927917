import {PayloadAction} from '@reduxjs/toolkit'
import * as actionTypes from '../actionTypes'
import {userActivityData} from '../data/userActivitiesData'
import {uploadReportData} from '../data/uploadReportData'
import {accountDetails} from '../../../_template/helpers/endpoints'
import {UsersState} from '../../type'

const initialState: UsersState = {
  usersList: [],
  sellers: [],
  uploadActivities: [],
  data: '',

  oneSeller: {
    shopName: '',
    status: '',
  },
  oneUploadActivity: uploadReportData[0],
  oneUser: {},
  totalPages: 1,
  totalItems: 1,
  roles: [],
  activities: [],
  accountDetails: {},
  UserActivitiesQuota: [],
}
export default function usersReducer(state = initialState, action: PayloadAction<any>): UsersState {
  switch (action.type) {
    case actionTypes.GET_ALL_USERS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        usersList: action.payload.data,

        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
      }
    }
    case actionTypes.GET_ALL_USER_ROLES: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        roles: action.payload,
      }
    }
    case actionTypes.GET_ACCOUNT_DETAILS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        accountDetails: action.payload.data,
      }
    }

    case actionTypes.GET_ONE_USER_BY_ID: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneUser: action.payload,
        UserActivitiesQuota: action.payload.subscription
          ? action.payload.subscription.actions
          : action.payload.actions,
      }
    }
    // case actionTypes.GET_ONE_USER_BY_ID: {
    //   return {
    //     // Again, one less level of nesting to copy
    //     ...state,
    //     oneUser: {
    //       ...action.payload,
    //       driverArea: action.payload.driverArea?.map((item: any) => ({
    //         ...item,
    //         label: item.name,
    //         value: item._id,
    //       })),
    //     },
    //   }
    // }
    case actionTypes.GET_ALL_SELLERS_BY_ID: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneSeller: action.payload,
      }
    }
    case actionTypes.GET_ONE_USER_ACTIVITY_BY_ID: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneUploadActivity: action.payload,
      }
    }
    case actionTypes.GET_ALL_SELLERS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        sellers: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
      }
    }
    case actionTypes.GET_ALL_USER_ACTIVITIES: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        uploadActivities: action?.payload.data,
        totalPages: action?.payload?.totalPages,
      }
    }
    case actionTypes.GET_USER_ACTIVITIES_QUOTA: {
      console.log('///' + JSON.stringify(action.payload.data))
      return {
        // Again, one less level of nesting to copy

        ...state,
        UserActivitiesQuota: action?.payload?.data,
      }
    }

    default:
      return state
  }
}
