export const SUCCESSFULLY_CREATED = '2000'
export const SUCCESSFULLY_DELETED = '2000'
export const SUCCESSFULLY_UPDATED = '2000'
export const NO_DATA_FOUND = '2002'
export const DATA_SUCCESSFULLY_RETRIEVED = "2000"

//error codes
export const USER_NOT_FOUND = "2001"
export const USER_INVALID_PASSWORD = "2001"
export const USER_IS_DESACTIVATE = 4102
export const USER_INACTIVE = 4103
export const USER_EMAIL_ALREADY_EXISTS = "2001"
export const USER_DOCUMENT_ALREADY_EXISTS = 4105
export const USER_HAS_DOCUMENT_TYPE = 4106
export const USER_EXISTS_ERROR = '2004'
export const USER_EMAIL_EXIST_ERROR = "2001"
export const USER_MOBILE_NUMBER_EXIST_ERROR = 4109
export const MISSING_VALUE = '2001'
