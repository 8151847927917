import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_template/helpers'
import dataAxios from '../../../../../modules/auth/core/dataAxios'
import { ISubscriptionpLAN } from '../../../../../type'
// import {Customer} from './_models'

const CATEGORY_URL = `/subscription_plan`
// const GET_USERS_URL = `/users/query`

// const getUsers = (query: string): Promise<UsersQueryResponse> => {
//   return axios
//     .get(`${GET_USERS_URL}?${query}`)
//     .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
// }

const getUserById = (id: ID): Promise<ISubscriptionpLAN | undefined> => {
  return axios
    .get(`${CATEGORY_URL}/${id}`)
    .then((response: AxiosResponse<Response<ISubscriptionpLAN>>) => response.data)
    .then((response: Response<ISubscriptionpLAN>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${CATEGORY_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${CATEGORY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
export function changeCategoryStatus(id: string, autoactivate: '0' | '1') {
  return dataAxios.patch(`${CATEGORY_URL}/${id}`, {
    autoactivate,
  })
}

export {deleteUser, deleteSelectedUsers, getUserById}
