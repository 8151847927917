export const ADD_ARTICLE = 'ADD_ARTICLE'
export const GET_ALL_FUNCTION = 'GET_ALL_FUNCTION'
export const GET_ONE_BY_ID = 'GET_ONE_BY_ID'
export const DELETE_ONE = 'DELETE_ONE'
export const REMOVE_ARTICLE = 'REMOVE_ARTICLE'
export const FETCH_START = 'FETCH_START'
export const UPDATING_CONTENT = 'UPDATING_CONTENT'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const FETCH_ERROR = 'FETCH_ERROR'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER'
export const GET_ONE_USER_BY_ID = 'GET_ONE_USER_BY_ID'
export const GET_ONE_SUBSCRIPTION_PLAN_BY_ID = 'GET_ONE_SUBSCRIPTION_PLAN_BY_ID'
export const GET_ALL_SUBSCRIPTIONS_CATEGORY = 'GET_ALL_SUBSCRIPTIONS_CATEGORY'
export const GET_ALL_ACTIONS = 'GET_ALL_ACTIONS'
export const GET_ALL_SELLERS = 'GET_ALL_SELLERS'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_USER_SUBSCRIPTIONS = 'GET_ALL_USER_SUBSCRIPTIONS'
export const GET_ALL_SUBSCRIPTIONS = 'GET_ALL_SUBSCRIPTIONS'
export const GET_ALL_SUGGESTIONS = 'GET_ALL_SUGGESTIONS'
export const GET_ONE_SUGGESTION = 'GET_ONE_SUGGESTION'
export const GET_ONE_ORDER = 'GET_ONE_ORDER'
export const GET_ONE_USER_SUBSCRIPTION = 'GET_ONE_USER_SUBSCRIPTION'
export const GET_ONE_USER_SUBSCRIPTION_TRACKING = 'GET_ONE_USER_SUBSCRIPTION_TRACKING'
export const GET_ONE_PLATFORM_ACTIVITY = 'GET_ONE_PLATFORM_ACTIVITY'

export const GET_ALL_TRACKING_STATUS = 'GET_ALL_TRACKING_STATUS'

export const GET_USER_ACTIVITIES_QUOTA = 'GET_USER_ACTIVITIES_QUOTA'

export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'
export const GET_ALL_SELLERS_BY_ID = 'GET_ALL_SELLERS_BY_ID'
export const GET_ONE_USER_ACTIVITY_BY_ID = 'GET_ONE_USER_ACTIVITY_BY_ID'
export const GET_ALL_USER_ROLES = 'GET_ALL_USER_ROLES'
export const GET_ALL_STATUS = 'GET_ALL_STATUS'
export const GET_ALL_STATISTICS = 'GET_ALL_STATISTICS'
export const _PER_DAY = '_PER_DAY'
export const GET_ALL_SUBSCRIPTION_REVENU = 'GET_ALL_SUBSCRIPTION_REVENU'
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS'
export const GET_POSITIONS_TO_GOOGLE_MAP = 'GET_POSITIONS_TO_GOOGLE_MAP'
export const GET_ONE_ORDER_TRACKING = 'GET_ONE_ORDER_TRACKING'

export const GET_ALL_USER_ACTIVITIES = 'GET_ALL_USER_ACTIVITIES'
export const GET_ACCOUNT_DETAILS = 'GET_ACCOUNT_DETAILS'
