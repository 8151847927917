import React, {FC, useState} from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import SubsctiptionsInfo from './subscriptionsInfo'
import InvoiceDetails from './invoice'
import ProductTab from './productTab'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_template/layout/core'
import InfoView from '../../../../../_template/layout/components/infoView'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'

const SubscriptionsDetailsTabs: FC = () => {
  const [key, setKey] = useState('order')
  const intl = useIntl()
  return (
    <div>
      <PageTitle
        breadcrumbs={getBreadCrambs(intl, {
          isActive: false,
          path: '/subscriptions',
          title: intl.formatMessage({id: 'GORELLA.COMMON.ORDER_LIST'}),
          isSeparator: false,
        })}
      >
        {intl.formatMessage({id: 'GORELLA.COMMON.SUBSCRIPTION_DETAILS'})}
      </PageTitle>
      <InfoView />
      {/* <Tabs
        className='nav-custom-tabs '
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k: any) => setKey(k)}
      > */}
        {/* <Tab eventKey='order' title={intl.formatMessage({id: 'GORELLA.COMMON.SUBSCRIPTION_DETAILS'})}> */}
          <SubsctiptionsInfo className='p-5' />
        {/* </Tab> */}
        
        {/* <Tab eventKey='product' title={intl.formatMessage({id: 'GORELLA.ORDER.PRODUCT_DETAILS'})}>
          <ProductTab className='p-5' />
        </Tab>
        <Tab eventKey='invoice' title={intl.formatMessage({id: 'GORELLA.ORDER.INVOICE_TITLE'})}>
          <InvoiceDetails className='p-5' />
        </Tab> */}
      {/* </Tabs> */}
    </div>
  )
}

export default SubscriptionsDetailsTabs
