import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ActionListHeader } from './components/header/ActionsHeader'
import DriverAreaTable from './table/actionTable'
// import CustomerEditModal from './customer-edit-modal/CustomerEditModal'
import { useAppDispatch } from '../../../../hooks'
import { useEffect } from 'react'
import { getAllFunction } from '../../../../redux/actions/commonCrud'
import InfoView from '../../../../../_template/layout/components/infoView'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../../_template/layout/core'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'

const ActionList = () => {
  const { itemIdForUpdate } = useListView()
  const intl = useIntl()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getAllFunction(endpoints.Actions, actionTypes.GET_ALL_ACTIONS, {}))
  }, [dispatch])

  return (
    <>
      <InfoView />
      <div
        className='d-flex justify-content-between'
        style={{ marginTop: '-3.2%', marginBottom: '25px' }}
      >
        <div>
          <PageTitle breadcrumbs={getBreadCrambs(intl)}>
            {intl.formatMessage({ id: 'GORELLA.PLATFORM_ACTIVITIES' })}
          </PageTitle>
        </div>
        {/* <div>
          <ActionListHeader />
        </div> */}
      </div>
      <DriverAreaTable />

      {/* <OrderStatusManagementListHeader /> */}
      {/* <OrderStatusManagementTable /> */}
      {/* {itemIdForUpdate !== undefined && <ActionsEditModalForm />} */}
    </>
  )
}

const DriverAreaWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ActionList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export default DriverAreaWrapper
