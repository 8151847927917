/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG } from '../../../_template/helpers'
import { getCSSVariableValue } from '../../../_template/assets/ts/_utils'
import { Dropdown1 } from '../../../_template/partials/content/dropdown/Dropdown1'
import { useThemeMode } from '../../../_template/partials/layout/theme-mode/ThemeModeProvider'
import { useLang } from '../../../_template/i18n/Metronici18n'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  sales: any
  title: any
}

const RoundedChart: React.FC<Props> = ({ className, chartColor, chartHeight, sales, title }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const locale: string = useLang()
  const { mode } = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    if (sales?.countries) {
      const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, sales))
      if (chart) {
        chart.render()
      }
      return chart
    }
    if (sales?.percentages) {
      const chart = new ApexCharts(
        chartRef.current,
        chartStatusOptions(chartColor, chartHeight, sales.percentages)
      )
      if (chart) {
        chart.render()
      }

      return chart
    }
  }
  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, sales])

  return (
    <>
      {sales?.countries && (
        <div className={`card ${className}`}>
          {/* begin::Beader */}
          <div className='card-header border-0 py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fs-6  text-bluee mb-1'>{title}</span>
              {/* <span className='text-muted fw-semibold fs-7'>Complete your profile setup</span> */}
            </h3>


          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className='card-body d-flex flex-column '>
            <div className='flex-grow-1' >
              <div ref={chartRef} className='mixed-widget-4-chart'></div>
            </div>

            <div className='pt-0'>
              <div className='d-flex flex-column content-justify-center flex-row-fluid'>
                {sales?.countries?.map((item: any) => (
                  <>{item.country}</>
                ))}

                {/* <div className='d-flex fw-semibold align-items-center my-3'>
                  <div
                    className='bullet w-8px h-8px rounded-2 me-3'
                    style={{backgroundColor: '#E9A800'}}
                  ></div>
                  <div className='text-gray-500 flex-grow-1 me-4'>
                    <FormattedMessage id='GORELLA.DASHBOARD.LAST.WEEK' />
                  </div>
                  <div className='fw-bolder text-gray-700 text-xxl-end'>
                    {sales?.lastWeek?.count}
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* end::Body */}
        </div>
      )}
      {sales?.percentages && (
        <div className={`card ${className}`}>
          {/* begin::Beader */}
          <div className='card-header border-0 py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label text-bluee fs-6 mb-1'>{title}</span>
              {/* <span className='text-muted fw-semibold fs-7'>Complete your profile setup</span> */}
            </h3>


          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className='card-body d-flex flex-column'>
            <div className='flex-grow-1'>
              <div ref={chartRef} className='mixed-widget-4-chart'></div>
            </div>

            <div className='pt-5'>
              <div className='d-flex flex-column content-justify-center flex-row-fluid'>
                {/* <div className='d-flex fw-semibold align-items-center my-3'>
                  <div
                    className='bullet w-8px h-8px rounded-2 me-3'
                    style={{backgroundColor: '#E9A800'}}
                  ></div>
                  <div className='text-gray-500 flex-grow-1 me-4'>
                    <FormattedMessage id='ECOMMERCE.DASHBOARD.LAST.WEEK' />
                  </div>
                  <div className='fw-bolder text-gray-700 text-xxl-end'>
                    {sales?.lastWeek?.count}
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* end::Body */}
        </div>
      )}
    </>
  )
}
const colorPalette = [
  "#ADD8E6", "#FFA500", "#D3D3D3", "#336699", "#FFA07A", "#808080", "#7FFF00", '#FFD1DC', "#98FF98", "#FFFF99"
]

const chartOptions = (chartColor: string, chartHeight: string, sales: any): ApexOptions => {
  const colors = sales.countries.map(
    (_: any, index: any) => colorPalette[index % colorPalette.length]
  );

  return {
    series: sales.percentage,
    chart: {

      width: '100%',
      type: 'pie',
    },
    labels: sales.countries,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    colors: colors,

  };
};

const chartStatusOptions = (chartColor: string, chartHeight: string, sales: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-700')

  // let sum = Object.values(sales).reduce((acc: any, key: any) => {
  //   return acc + key
  // }, 0)
  // const delivered = sales.find((item: any) => item.status?.name?.toUpperCase() === 'FREE')
  // const value = delivered?.count ? (delivered?.count / sum) * 100 : 0
  //const valueToFixed = value?.toFixed(2)
  return {
    chart: {
      fontFamily: 'inherit',

      width: "100%",
      type: 'donut',
    },

    plotOptions: {
      pie: {
        donut: {
          labels: {
            name: {
              show: false,
              fontWeight: '700',
            },
            value: {
              color: labelColor,
              fontSize: '30px',
              fontWeight: '700',
              offsetY: 12,
              show: true,
              formatter: function (val) {
                return val + '%'
              },
            },
          },
        },
      },
    },

    stroke: {
      lineCap: 'round',
    },

    labels: sales && Object.keys(sales),
    series: sales && Object.values(sales),
    colors: colorPalette
  }
}

export { RoundedChart }
