import {FC, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {useAppDispatch} from '../../../../app/hooks'
import {getAllFunction} from '../../../../app/redux/actions/commonCrud'
import {GET_ALL_SUBSCRIPTIONS_CATEGORY, GET_ALL_USERS} from '../../../../app/redux/actionTypes'
import {SubscriptionPlan, userEndpoint} from '../../../helpers'
import {useIntl} from 'react-intl'
import {changeCategoryStatus} from '../../../../app/pages/configuration/ListSubscriptionPlan/subscription-plan-list/core/_requests'

type Props = {
  two_steps?: string
  id: string
  type: 'user' | 'driver'
}
const UserTwoStepsCell: FC<Props> = ({two_steps, id, type}) => {
  const [show, setShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  // const [changedStatus, setChangedStatus] = useState(two_steps)
  const intl = useIntl()

  const dispatch = useAppDispatch()
  const handleDispatch = () => {
    dispatch(
      getAllFunction(SubscriptionPlan, GET_ALL_SUBSCRIPTIONS_CATEGORY, {})
    )
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const updateUserStatus = (id: string, autoactivate: '0' | '1') => {
    setSubmitting(true)
    changeCategoryStatus(id, autoactivate)
      .then(() => {
        // HERE SHOULD REFETCH
        handleDispatch()
        setSubmitting(false)
        // setChangedStatus(status)
        handleClose()
      })
      .catch((err) => {
        setSubmitting(false)
      })
  }

  return (
    <div>
      {/* <div onClick={}> */}
      {two_steps && two_steps === '1' ? (
        <button onClick={handleShow} className='btn btn-sm custom-btn-success '>
          {intl.formatMessage({id: 'GORELLA.COMMON.AVAILABLE'})}
        </button>
      ) : (
        <button onClick={handleShow} className='btn btn-sm custom-btn-danger'>
          {intl.formatMessage({id: 'GORELLA.COMMON.DISABLED'})}
        </button>
      )}
      {/* </div> */}

      <Modal
        style={{
          zIndex: 10000,
        }}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-7'>{intl.formatMessage({id: 'GORELLA.COMMON.CHANGE_USER_STATUS'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <span className='text-darkblue fs-8'> {intl.formatMessage({id: 'GORELLA.COMMON.CHANGE_USER_STATUS_TO'})}{' '}</span> 
          <span className={`${two_steps === '1' ? 'text-danger' : 'text-success'} fw-bolder fs-8`}>
            {two_steps === '1'
              ? intl.formatMessage({id: 'GORELLA.COMMON.DISABLED'})
              : intl.formatMessage({id: 'GORELLA.COMMON.AVAILABLE'})}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button className="fs-7"variant='secondary' onClick={handleClose}>
            {intl.formatMessage({id: 'GORELLA.COMMON.CLOSE'})}
          </Button>
          <Button className='fs-7'
            disabled={submitting}
            variant={two_steps === '1' ? 'danger' : 'success'}
            onClick={() => {
              updateUserStatus(id, two_steps === '1' ? '0' : '1')
            }}
          >
            {intl.formatMessage({id: 'GORELLA.COMMON.UPDATE_STATUS'})}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export {UserTwoStepsCell}
