const DocsCount = ({ count, title, showNumbers }: {
    count: number;
    title: string;
    showNumbers: boolean;
}) => {

    return (
        showNumbers ?
            <div className='d-flex flex-row'>
                <div className='mx-auto d-flex flex-row gap-3'>
                    <div style={{
                        color: "#575C76",
                        backgroundColor: "transparent",
                        marginBottom: "1rem"

                    }} > {title} ({count})</div>
                    {/* <div style={{
                        color: "#575C76",
                        backgroundColor: "transparent",
                        // borderRadius: 8
                    }} className='fs-8 px-2 py-1'>{count}</div> */}
                </div>
            </div> : <div>{title}</div>
    )
}

export default DocsCount