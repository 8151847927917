import {PayloadAction} from '@reduxjs/toolkit'
import * as actionTypes from '../actionTypes'
import { ActivitiesState } from '../../type'

const initialState: ActivitiesState = {
  userSubscriptions: [],
  oneOrder: {},
  data: '',
  actions: [],
  OneUserSubscriptionStatus: [],
  subscriptions: [],
  OneUserSubscription: {},
  totalPages: 1,
  totalItems: 0,
}
export default function subscriptionsReducer(
  state = initialState,
  action: PayloadAction<any>
): ActivitiesState {
  switch (action.type) {
    case actionTypes.GET_ONE_USER_SUBSCRIPTION: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        OneUserSubscription: action?.payload[0]?.data,
      }
    }

    case actionTypes.GET_ALL_USER_SUBSCRIPTIONS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        userSubscriptions: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
      }
    }
    case actionTypes.GET_ALL_SUBSCRIPTIONS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        subscriptions: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
      }
    }

    default:
      return state
  }
}
