import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {useLang} from '../../../i18n/Metronici18n'
import LogoutModal from '../../../../app/components/logoutModal'
import {useState} from 'react'

const SidebarLogo = () => {
  const token = localStorage.getItem('token')
  const [showLogoutModal, setShowLogoutModal] = useState(token ? false : true)
  const {config} = useLayout()
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default
  const locale: string = useLang()
  return (
    <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo' style={{backgroundColor:"#F1F2F7"}}>
      <Link to='/dashboard'>
        {/* {config.layoutType === 'dark-sidebar' ? (
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/new-logo.png')}
            className='h-25px app-sidebar-logo-default'
          />
        ) : ( */}
        <>
          <div className='w-100' style={{marginRight: '45px'}}>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo.png')}
              className='app-sidebar-logo-default theme-light-show h-70px'
            />
          </div>

          {/* <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/new-logo.png')}
              className='h-25px app-sidebar-logo-default theme-dark-show'
            /> */}
        </>
        {/* )} */}

        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/BasicLogoMinimized.png')}
          className='h-25px app-sidebar-logo-minimize'
        />
      </Link>

      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id='kt_app_sidebar_toggle'
          // style={{
          //   left: locale === 'ar' ? -5 : undefined,
          //   right: locale !== 'ar' ? -30 : undefined,
          // }}
          // className={clsx(
          //   'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50   translate-middle rotate',
          //   {active: appSidebarDefaultMinimizeDefault}
          // )}
          // data-kt-toggle='true'
          // data-kt-toggle-state={toggleState}
          // data-kt-toggle-target='body'
          // data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          {/* <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-2 rotate-180' /> */}
        </div>
      )}
      {showLogoutModal && (
        <LogoutModal show={showLogoutModal} handleClose={() => setShowLogoutModal(false)} />
      )}
    </div>
  )
}

export {SidebarLogo}
