import { PayloadAction } from "@reduxjs/toolkit";
import * as actionTypes from "../actionTypes";
import { statisticsData } from "../data/statisticsData";
import { DateData, Series, StatisticsState } from "../../type";

const initialState: StatisticsState = {
  statistics: statisticsData.data,
  activities: {},
  info: {},
  userPercentageByCountry: {},
  subscriptionPercentages: {},
  data: [],
  revenue: [],
  totalUsers: 0,
  totalRevenu: 0,
  totalActivites: 0,
  xaxisCategories: [],
};
interface Data {
  [date: string]: DateData;
}

export default function statisticsReducer(
  state = initialState,
  action: PayloadAction<any>
): StatisticsState {
  switch (action.type) {
    case actionTypes.GET_ALL_STATISTICS: {
      return {
        // Again, one less level of nesting to copy
        ...state,

        userPercentageByCountry:
          action.payload?.data?.user_percentage_by_country.reduce(
            (accumulator: any, currentValue: any) => ({
              countries: [...accumulator.countries, currentValue.country],
              percentage: [...accumulator.percentage, currentValue.percentage],
            }),
            { countries: [], percentage: [] }
          ),
        totalUsers: action.payload?.data?.total_users,
        // activities: action.payload?.data?.activities,
      };
    }

    case actionTypes._PER_DAY: {
      console.log("action.payload.data" + JSON.stringify(action.payload.data));

      const filteredData = Object.entries(
        action.payload.data as {
          [date: string]: DateData;
        }
      )
        .filter(([key]) => key !== "total")
        .reduce((result, [date, dateData]) => {
          const dayOfWeek = new Date(date).toLocaleDateString("en-US", {
            weekday: "short",
          });
          const dateActions = dateData.actions;

          result[dayOfWeek] = Object.entries(dateActions).map(
            ([actionName, value]) => ({
              name: actionName,
              data: [value],
            })
          );

          return result;
        }, {} as { [day: string]: Series[] });

      console.log("filteredData" + JSON.stringify(filteredData));

      return {
        ...state,
        xaxisCategories: Object.keys(filteredData), // Update x-axis categories with days
        activities: filteredData,
        totalActivites:
          action.payload.data?.total?.total_remove_bg +
          action.payload.data?.total?.total_add_bg,
      };
    }

    case actionTypes.GET_ALL_SUBSCRIPTION_REVENU: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        revenue: Object.values(
          action.payload[0].data.subscription_increase
        ).map((data: any) =>
          // data.percentage_increase
          data.percentage_increase === -100 ? 0 : data.percentage_increase
        ),
        subscriptionPercentages:
          action.payload[0].data?.subscription_percentages,
        totalRevenu: action.payload[0].data?.total_subscription_revenue,
      };
    }

    default:
      return state;
  }
}
