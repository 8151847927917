import React, {useState} from 'react'
import {Pagination as BootstrapPagination, Pagination} from 'react-bootstrap'
import {useAppDispatch} from '../../../../app/hooks'
import {getAllFunction} from '../../../../app/redux/actions/commonCrud'
import {useSelector} from 'react-redux'

type values = {
  role?: string | undefined
  phone?: string | undefined
  status?: string | undefined
  firstname?: string | undefined
  lastname?: string | undefined
  user_id?: string | undefined,
  email?: string | undefined
  name?:string | undefined
  code?:string | undefined
}

type IPaginationProps = {
  values: values | undefined
  setValues: any
  endpoint: string
  actionType: string
  totalPages: number
}

const PaginationComponent = ({
  values,
  setValues,
  endpoint,
  actionType,
  totalPages,
}: IPaginationProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const dispatch = useAppDispatch()

  const fetchData = async (page: number) => {
    await dispatch(getAllFunction(endpoint, actionType, {...values, page}))
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
    setValues({...values, page: pageNumber})
    fetchData(pageNumber)
  }

  const createPaginationItem = (i: number) => (
    <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
      {i}
    </Pagination.Item>
  )
  const generatePaginationItems = () => {
    const paginationItems: any = [];
  
    if (totalPages < 1) {
      return paginationItems; // No need for pagination controls
    }
  
    // Always include the first page
    paginationItems.push(createPaginationItem(1));
  
    if (totalPages > 1) {
      if (totalPages === 2) {
        // Only two pages, include the second page
        paginationItems.push(createPaginationItem(2));
      } else {
        // More than two pages
        if (currentPage <= 4) {
          const numPagesToShow = Math.min(5, totalPages - 1);
          for (let i = 2; i <= numPagesToShow; i++) {
            paginationItems.push(createPaginationItem(i));
          }
          if (totalPages > 6) {
            paginationItems.push(<Pagination.Ellipsis key='ellipsis-end' />);
          }
        } else if (currentPage >= totalPages - 3) {
          if (totalPages > 6) {
            paginationItems.push(<Pagination.Ellipsis key='ellipsis-start' />);
          }
          const startPoint = Math.max(2, totalPages - 5);
          for (let i = startPoint; i <= totalPages - 1; i++) {
            paginationItems.push(createPaginationItem(i));
          }
        } else {
          paginationItems.push(<Pagination.Ellipsis key='ellipsis-start' />);
          for (let i = currentPage - 1; i <= currentPage + 1; i++) {
            paginationItems.push(createPaginationItem(i));
          }
          paginationItems.push(<Pagination.Ellipsis key='ellipsis-end' />);
        }
      }
  
      // Always include the last page
      paginationItems.push(createPaginationItem(totalPages));
    }
  
    return paginationItems;
  };
  
  return (
    <Pagination className='paginationInfo'>
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {generatePaginationItems()}
      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  )
}

export default PaginationComponent
