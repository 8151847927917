import React, {FC} from 'react'
import {PageTitle} from '../../../_template/layout/core'
// import {RelayPointsPage} from './RelayPointsPage'
import {useIntl} from 'react-intl'
import {AllUsersActivitiesWrapper} from './AllUsersActivities-list/AllUsersActivitiesList'

const AllUsersActivitiesPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.USER.ACTIVITIES'})}</PageTitle>
      <AllUsersActivitiesWrapper />
    </>
  )
}

export default AllUsersActivitiesPageWrapper
