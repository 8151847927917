/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '../../../assets/ts/components'
import {ID, KTSVG} from '../../../helpers'
import {Link} from 'react-router-dom'
import {useLang} from '../../../i18n/Metronici18n'
import {useIntl} from 'react-intl'

type Props = {
  id: ID
}

const MainOrderActionsCell: FC<Props> = ({id}) => {
  const locale: string = useLang()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const intl = useIntl()
  return (
    <>
      <a
        href='#'
        className='btn btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
      >
        {/* <KTSVG path='/media/icons/duotune/print/dots.svg' className='svg-icon-4 ml-5' />{' '} */}
     <img src='/media/icons/duotune/print/dots.png'/>
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fs-8 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link className='menu-link px-3 text-darkblue' to={`/subscriptions/${id}`}>
            {intl.formatMessage({id: 'GORELLA.COMMON.DETAILS'})}
          </Link>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {MainOrderActionsCell}
