import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {useQueryResponseLoading} from '../core/QueryResponseProvider'
import {usersColumns} from './columns/_columns'
// import { User } from '../core/_models';
import {UserActivitiesListLoading} from '../components/loading/UserActivitiesListLoading'
// import {UsersListPagination} from '../components/pagination/UsersListPagination'
import {KTCard, KTCardBody} from '../../../../../_template/helpers'
import {useSelector} from 'react-redux'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './CustomRow'
import {UserActivitiesSearchComponent} from '../components/header/UserActivitiesListSearch'
import PaginationComponent from '../../../../../_template/layout/components/pagination'
import {getAllFunction} from '../../../../redux/actions/commonCrud'
import {useAppDispatch} from '../../../../hooks'
// import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import {useParams} from 'react-router-dom'
import {IAllUsersActivities, IUsers, UsersState} from '../../../../type'

type Props = {
  eventKey?: string | undefined
  setCount: any
}
type values = {
  role?: string | undefined
  phoneNumber?: string | undefined
  status?: string | undefined
  firstname?: string | undefined
  lastname?: string | undefined
  email?: string | undefined
}
const ActivitiesByUSerTable: React.FC<Props> = ({eventKey, setCount}) => {
  const user: IAllUsersActivities[] = useSelector(({users}: {users: UsersState}) => {
    return users.uploadActivities
  })
  const totalPages: number = useSelector(({users}: {users: UsersState}) => {
    return users.totalPages
  })
  const totalItems: number = useSelector(({users}: {users: UsersState}) => {
    return users.totalItems
  })

  const [values, setValues] = useState<values | undefined>({
    status: eventKey === 'All' ? undefined : eventKey,
  })
  const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,

      [e.target.name]: e.target.value,
    })
  }
  const isLoading = useQueryResponseLoading()
  const data: any[] = useMemo(() => user, [user])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const {id} = useParams()
  const dispatch = useAppDispatch()
  const handleDispatch = () => {
    dispatch(
      getAllFunction(endpoints.userActivitiesEndpoint, actionTypes.GET_ALL_USER_ACTIVITIES, {
        user_id: id,
        ...values,
      })
    )
  }
  const resetData = () => {
    setValues({firstname: '', lastname: '', role: '', phoneNumber: ''})
  }

  useEffect(() => {
    dispatch(
      getAllFunction(endpoints.userActivitiesEndpoint, actionTypes.GET_ALL_USER_ACTIVITIES, {
        user_id: id,
        ...values,
      })
    )
    if (totalItems) {
      setCount(totalItems)
    } else {
      setCount(0)
    }
  }, [totalItems])
  return (
    <KTCard>
      <KTCardBody className='py-4'>
        <div className='p-10 fw-bold fs-3'>
          {data[0] && data[0]?.user_info?.firstname + ' ' + data[0]?.user_info?.lastname}
        </div>
        <div className='w-md-100'>
          <UserActivitiesSearchComponent
            resetData={resetData}
            values={values}
            handleChangeValues={handleChangeValues}
            handleDispatch={handleDispatch}
            eventKey={eventKey}
          />
        </div>
        {/* </div> */}
        <div className='table-responsive' style={{overflow: 'hidden'}}>
          <table
            id='kt_table_users'
            className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead style={{backgroundColor: '#EFF0F4'}}>
              <tr className='text-bluee fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IUsers>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='table-customized' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IUsers>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* <UsersListPagination /> */}
        <PaginationComponent
          values={{...values, user_id: id}}
          setValues={setValues}
          endpoint={endpoints.userEndpoint}
          actionType={actionTypes.GET_ALL_USERS}
          totalPages={totalPages}
        />
        {isLoading && <UserActivitiesListLoading />}
      </KTCardBody>
    </KTCard>
  )
}

export {ActivitiesByUSerTable}
