/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG } from '../../../_template/helpers'
import { getCSSVariableValue } from '../../../_template/assets/ts/_utils'
import { Dropdown1 } from '../../../_template/partials/content/dropdown/Dropdown1'
import { useThemeMode } from '../../../_template/partials/layout/theme-mode/ThemeModeProvider'
import { useLang } from '../../../_template/i18n/Metronici18n'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  sales: any
  title: any
}

const StatsByCountry: React.FC<Props> = ({ className, chartColor, chartHeight, sales, title }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const locale: string = useLang()
  const { mode } = useThemeMode()
  const refreshChart = () => {
    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, sales))
    if (chart) {
      chart.render()
    }

    return chart
  }
  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, sales])

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Beader */}
        <div className='card-header border-0 py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fs-6 text-bluee mb-1'>{title}</span>
            {/* <span className='text-muted fw-semibold fs-7'>Complete your profile setup</span> */}
          </h3>

          <div className='card-toolbar'>
            {/* begin::Menu */}
            {/* <a href='#'>
              <i className='bi bi-three-dots fs-5'></i>
            </a> */}
            {/* <Dropdown1 /> */}
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body d-flex flex-column'>
          <div className='flex-grow-1'>
            <div ref={chartRef} className='mixed-widget-4-chart'></div>
          </div>

          <div className='pt-5'>
            <div className='d-flex flex-column content-justify-center flex-row-fluid'>
              {sales.map((item: any) => (
                <></>
              ))}

              {/* <div className='d-flex fw-semibold align-items-center my-3'>
                  <div
                    className='bullet w-8px h-8px rounded-2 me-3'
                    style={{backgroundColor: '#E9A800'}}
                  ></div>
                  <div className='text-gray-500 flex-grow-1 me-4'>
                    <FormattedMessage id='GORELLA.DASHBOARD.LAST.WEEK' />
                  </div>
                  <div className='fw-bolder text-gray-700 text-xxl-end'>
                    {sales?.lastWeek?.count}
                  </div>
                </div> */}
            </div>
          </div>
        </div>

        {/* end::Body */}
      </div>
    </>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, sales: any): ApexOptions => {
  const baseColor = '#ADD8E6'
  const lightColor = '#dbdbdb'
  const labelColor = getCSSVariableValue('--bs-gray-700')
  const current = parseFloat(sales.currentWeek?.count)
  const last = parseFloat(sales.lastWeek?.count)

  //const value = (current / (current + last)) * 100
  //const valueToFixed = value.toFixed(2)

  return {
    series: [
      {
        name: 'sales',
        data: sales,
        color: chartColor
      },
    ],
    chart: {
      height: 350,

      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 7,

        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + '%'
      },
      offsetY: -20,
      style: {
        fontSize: '8px',
        colors: ['#575C76'],
      },
    },

    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      position: 'bottom',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: baseColor,
            colorTo: baseColor,
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + '%'
        },
      },
    },
  }
}

export { StatsByCountry }
