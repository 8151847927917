import React from 'react';
import { useSelector } from 'react-redux';
import { MessageView } from '../messageView';
import { CommonState } from '../../../../app/type';

const InfoView = () => {
  const commonState: CommonState = useSelector(({ common }: { common: CommonState }) => common);

  const showMessage = () => {
    return <MessageView variant='alert-success' message={commonState.message.toString()} />;
  };

  const showError = () => {
    return <MessageView variant='alert-danger' message={commonState.error.toString()} />;
  };

  return (
    <>
      {/* {loading && <Loader />} */}

      {commonState.message && (
        <div className="fs-7" style={{width:"325px"}}> {/* Change the width as needed */}
          {showMessage()}
        </div>
      )}
      {commonState.error && (
        <div className="fs-7" style={{width:"325px"}}> {/* Change the width as needed */}
          {showError()}
        </div>
      )}
    </>
  );
};

export default InfoView;
