/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from '../../../_template/helpers'

type Props = {
  className: string
  title: string
  value: string
  avatar: string
  percentage: string
}

const StatisticsWidget2: React.FC<Props> = ({className, title, value, avatar, percentage}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex align-items-center pt-1 pb-0' style={{borderRadius: '28px'}}>
        <div className='d-flex flex-column flex-grow-1 py-1 py-lg-10 me-2'>
          <span className='text-black fs-6 mb-6'> {title}</span>
          <a href='#' className='fw-bold text-bluee fs-3 mb-2 text-hover-primary'>
            {value}
          </a>
        </div>{' '}
        {/* <span className='badge badge-light-success  fw-bold align-self-center mx-8 '  style={{fontSize: '1rem'}}>
          {percentage}
        </span> */}
        <img
          src={toAbsoluteUrl(avatar)}
          alt=''
          className='align-self-center '
          style={{height: '40px'}}
        />
      </div>
      {/* end::Body */}
    </div>
  )
}

export {StatisticsWidget2}
