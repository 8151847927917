import { PayloadAction } from '@reduxjs/toolkit'
import * as actionTypes from '../actionTypes'
import { categoryData } from '../data/categoryData'
import { notificationsData } from '../data/notificationsData'
import { ConfigState } from '../../type'

const initialState: ConfigState = {
  subscriptionCategory: categoryData,
  oneSubscriptionPlan: {},
  totalItems: 1,
  all_status: [],
  oneNotification: { users: [], order: [] },
  allNotifications: notificationsData,
  //   data: '',

  oneActivity: undefined,
  //   data: '',
  activities: [],
  totalPages: 0,
}
export default function configReducer(
  state = initialState,
  action: PayloadAction<any>
): ConfigState {
  switch (action.type) {
    case actionTypes.GET_ONE_SUBSCRIPTION_PLAN_BY_ID: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneSubscriptionPlan: action.payload[0].data,
      }
    }
    case actionTypes.GET_ALL_SUBSCRIPTIONS_CATEGORY: {

      return {
        // Again, one less level of nesting to copy
        ...state,
        subscriptionCategory: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems
      }
    }
    case actionTypes.GET_ALL_NOTIFICATIONS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        allNotifications: action.payload.data,
        totalPages: action.payload.totalPages,
      }
    }
    case actionTypes.GET_ALL_STATUS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        totalPages: action.payload.totalPages,
        all_status: action.payload.data,
      }
    }
    case actionTypes.GET_ALL_ACTIONS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        totalPages: action.payload.totalPages,
        activities: action.payload.data,
        totalItems: action.payload.totalItems
      }
    }
    case actionTypes.GET_ONE_PLATFORM_ACTIVITY: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneActivity: action.payload,
      }
    }


    default:
      return state
  }
}
