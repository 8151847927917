/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { MenuComponent } from '../../../assets/ts/components'
import { ID, KTSVG, QUERIES } from '../../../helpers'
// import { useListView } from '../../../../app/pages/configuration/ListDelivery/deliveryType-list/core/ListViewProvider'
// import { useQueryResponse } from '../../../../app/pages/configuration/ListDelivery/deliveryType-list/core/QueryResponseProvider'
// import { deleteUser } from '../../../../app/pages/configuration/ListDelivery/deliveryType-list/core/_requests'
// import { Link } from 'react-router-dom'
import { useLang } from '../../../i18n/Metronici18n'
// import { getOrderStatus, postOrderStatu } from '../../../../app/modules/auth/core/_requests'
import ActionsDeleteForm from '../../../../app/components/ActionsDeleteForm'
import { useIntl } from 'react-intl'
import ActionsForm from '../../../../app/pages/configuration/Actions/Actions-list/Actions-edit-modal/ActionsForm'

type Props = {
  id: ID
}

const ActionsCell: FC<Props> = ({ id }) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const intl = useIntl()

  const locale: string = useLang()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <a
        href='#'
        className='btn  btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
      >
        <img src='/media/icons/duotune/print/dots.png' />{' '}
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary  fs-8 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a onClick={() => { setShowEditForm(true) }} className='menu-link px-3 text-darkblue' >
            {intl.formatMessage({ id: 'GORELLA.COMMON.EDIT' })}
          </a>
        </div>

        <div className='menu-item px-3'>
          <div onClick={() => { setShowDeleteForm(true) }} className='menu-link px-3 text-darkblue' >
            {intl.formatMessage({ id: 'GORELLA.COMMON.DELETE' })}
          </div>
        </div>
        {showEditForm && <ActionsForm show={showEditForm} handleClose={() => setShowEditForm(false)} id={id as string} />}
        {showDeleteForm && <ActionsDeleteForm show={showDeleteForm} handleClose={() => setShowDeleteForm(false)} id={id as string} />}


        { }
      </div>
      {/* end::Menu */}
    </>
  )
}

export default ActionsCell 
