import {combineReducers, configureStore} from '@reduxjs/toolkit'
import commonReducer from '../reducers/common'
// import commonReducer from '../reducers/common'
import usersReducer from '../reducers/users'
import subscriptionsReducer from '../reducers/subscription'
import configReducer from '../reducers/config'
import statisticsReducer from '../reducers/statistics'
const rootReducer = combineReducers({
  users: usersReducer,
  activities: subscriptionsReducer,
  config: configReducer,
  common: commonReducer,
  stats: statisticsReducer,
  // Add other reducers here as needed
})
export const store = configureStore({
  reducer: rootReducer,
})
// let currentState = store.getState()

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
