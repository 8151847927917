import { useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { useQueryResponseLoading } from '../core/QueryResponseProvider'
import driverAreaColumns from './columns/_columns'
// import {Customer} from '../core/_models'
import DriverAreaLoading from '../components/loading/driverAreaLoading'
// import CustomersListPagination from '../components/pagination/CustomersListPagination'
import { KTCard, KTCardBody } from '../../../../../../_template/helpers'
import { useSelector } from 'react-redux'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import DriverAreaSearchComponent from '../components/header/ActionsFilterst'
import { useAppDispatch } from '../../../../../hooks'
import { getAllFunction } from '../../../../../redux/actions/commonCrud'
import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../redux/actionTypes'
import PaginationComponent from '../../../../../../_template/layout/components/pagination'
import TSubscriptionPlanFilterValues from '../../../../../types/TSubscriptionPlanFilterValues'
import { ConfigState } from '../../../../../type'
import { useIntl } from 'react-intl'
import DocsCount from '../../../../../components/DocsCount'

const ActionTable: React.FC = () => {
  const activities = useSelector(({ config }: { config: ConfigState }) => config.activities)
  const totalPages = useSelector(({ config }: { config: ConfigState }) => config.totalPages)
  const totalitems = useSelector(({ config }: { config: ConfigState }) => config.totalItems)
  const intl = useIntl()
  const [filterValues, setFilterValues] = useState<TSubscriptionPlanFilterValues>()
  const handleChangeValues = (e?: React.ChangeEvent<any>) => {
    if (e) {
      setFilterValues({
        // ...filterValues,
        // ...filterObject,
        [e.target.name]: e.target.value,
      })
    } else {
      setFilterValues(undefined)
    }
  }

  const dispatch = useAppDispatch()
  const handleDispatch = () => {
    dispatch(
      getAllFunction(endpoints.Actions, actionTypes.GET_ALL_ACTIONS, {
        ...filterValues,
      })
    )
  }

  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => activities, [activities])
  const columns = useMemo(() => driverAreaColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <KTCard>
      <KTCardBody className='py-4'>
        <div className='d-flex justify-content-between'>
          <div className='w-100'>
            <DriverAreaSearchComponent
              filterValues={filterValues}
              handleChangeValues={handleChangeValues}
              handleDispatch={handleDispatch}
            // eventKey={eventKey}
            />
          </div>
          {/* <div style={{width: '180px'}}>
            <UsersListFilter eventKey={eventKey} />
          </div> */}
        </div>
        <div className='d-flex' style={{ marginTop: "-2.8rem" }}>
          <DocsCount
            showNumbers={true}
            count={totalitems}

            title={intl.formatMessage({ id: "MENU.ACTIONS" })}
          />
        </div>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead style={{ backgroundColor: "#EFF0F4" }}>
              <tr className='text-bluee fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<any>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-500 fs-6' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<any>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <CustomersListPagination /> */}
        <PaginationComponent
          values={filterValues}
          setValues={setFilterValues}
          endpoint={endpoints.Actions}
          actionType={actionTypes.GET_ALL_ACTIONS}
          totalPages={totalPages}
        />
        {isLoading && <DriverAreaLoading />}
      </KTCardBody>
    </KTCard>
  )
}

export default ActionTable
