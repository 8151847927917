// @ts-nocheck
import { Column } from 'react-table'

import { User } from '../../core/_models'
import { FormattedMessage, useIntl } from 'react-intl'
// import {CustomerActionsCell} from '../../../../../_template/layout/components/columns/CustomerActionsCell'
// import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'
// import { CustomerActionsCell } from '../../../../../../../_template/layout/components/columns/CustomerActionsCell'
import {
  UserCustomHeader,
} from '../../../../../../../_template/layout/components/columns'
import { DateCell } from '../../../../../../../_template/layout/components/columns/DateAnTimeCell'
import PlatformActivitesActionsCell from '../../../../../../../_template/layout/components/columns/ActionsCell'
import { SelectionCell } from './selectionCell'
import { SelectionHeader } from './selectionHeader'
import { Link } from 'react-router-dom'

const driverAreaColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <SelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index]._id} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.NAME' />}
        className='min-w-100px'
      />
    ),
    accessor: 'name',
    // Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.DESCRIPTION' />}
        className='min-w-125px'
      />
    ),
    accessor: 'description',
    // Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.CODE' />}
        className='min-w-100px'
      />
    ),
    accessor: 'code',
  },
  // Becouse viewing the radius, lat and long as plain numbers will not be if usefullness to the user.
  // Thus the view butten will direct the user to a maps component that will have the point on the map of the area.
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.CREATIONDATE' />}
        className='min-w-80px'
      />
    ),

    id: 'createDate',
    Cell: ({ ...props }) => <DateCell date={props.data[props.row.index].creation_date} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='MENU.ACTIONS' />}
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <PlatformActivitesActionsCell id={props.data[props.row.index]._id} />,
  },
]

export default driverAreaColumns
