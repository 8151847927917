/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../../../hooks'
import {getAllFunction, getOneFunctionById} from '../../../../../redux/actions/commonCrud'
// import {Link} from 'react-router-dom'
// import { KTSVG } from '../../../../../../components/helpers'
import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../redux/actionTypes'
import {KTCard, KTCardBody, toAbsoluteUrl} from '../../../../../../_template/helpers'
import {PageTitle} from '../../../../../../_template/layout/core'
import {useParams} from 'react-router-dom'
import getBreadCrambs from '../../../../../routing/breadcramps/getBreadCrambs'
import DataListLoading from '../../../../../components/dataLoader'
import { ConfigState, ISubscriptionpLAN } from '../../../../../type'

import { useTable } from 'react-table'

import {useMemo} from 'react'
import {useQueryResponseLoading} from '../core/QueryResponseProvider'
import driverAreaColumns from '../../../Actions/Actions-list/table/columns/_columns'
import TSubscriptionPlanFilterValues from '../../../../../types/TSubscriptionPlanFilterValues'
import DriverAreaSearchComponent from '../../../Actions/Actions-list/components/header/ActionsFilterst'
export function SubscriptionPlanInfo() {
  const {id} = useParams()
  const oneSubscriptionPlan: ISubscriptionpLAN | undefined = useSelector(
    ({config}: {config: ConfigState}) => {
      return config.oneSubscriptionPlan
    }
  )
  const intl = useIntl()

  const blankImg = toAbsoluteUrl('/media/svg/avatars/avatar_obj.svg')
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setLoading(true)
    dispatch(
      getOneFunctionById(
        endpoints.SubscriptionPlan,
        actionTypes.GET_ONE_SUBSCRIPTION_PLAN_BY_ID,
        id
      )
    )

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [dispatch, id])
  const activities = useSelector(({config}: {config: ConfigState}) => config.activities)
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => activities, [activities])
  const columns = useMemo(() => driverAreaColumns, [])
  const {getTableProps, getTableBodyProps,} = useTable({
    columns,
    data,
  })

  return (
    <>
      {loading && <DataListLoading />}
      {/* <InfoView /> */}
      <div className='d-flex justify-content-between'>
        <PageTitle
          breadcrumbs={getBreadCrambs(intl, {
            isActive: false,
            path: '/configutation/categories',
            title: 'Subscription Plan',
            isSeparator: false,
          })}
        >
          {/* {intl.formatMessage({id: 'GORELLA.USERS.USERS_LIST'})} */}
          {intl.formatMessage({id: 'GORELLA.DELIVERY.TYPE.DETAILS'})}
        </PageTitle>
      </div>

      {/* Container */}

      <KTCard>
      <KTCardBody className='py-4'>
        <div className='d-flex justify-content-between'>
        </div>

        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead style={{backgroundColor:"#EFF0F4"}}>
              <tr className='text-bluee fw-bolder fs-7 text-uppercase gs-0'>
               <td> {intl.formatMessage({id: 'GORELLA.COMMON.NAME'})} </td>
               <td>{intl.formatMessage({id: 'GORELLA.COMMON.CODE'})}</td>
               <td>{intl.formatMessage({id: 'GORELLA.COMMON.ACTIONS'})}</td>
              </tr>
            </thead>
            <tbody className='text-gray-500 fs-6' {...getTableBodyProps()}>
              <td> {`${oneSubscriptionPlan?.name}`}</td>
              <td> {`${oneSubscriptionPlan?.code}`}</td>
              <td > {oneSubscriptionPlan?.actions?.map((action, index) => (
                <div style={{backgroundColor:"#EFF0F4" ,borderRadius:"0.1rem"}} key={index}>
                  <div className='d-flex justify-content-between  ' >
                  <div>{action.name}</div>
                  <div
                    style={{ paddingRight: '65px', marginLeft: '10px', fontSize: '12.75px'}}
                  >
                    {action.quantity}
                  </div>
                </div>
               
                </div>
              ))}</td>

            </tbody>
          </table>
        </div>
      </KTCardBody>
    </KTCard>
    </>
  )
}


