import {AxiosResponse} from 'axios'
import * as actionTypes from '../actionTypes'
import dataAxios from '../../modules/auth/core/dataAxios'
import {Action, Dispatch} from '@reduxjs/toolkit'
// import { fetchSuccess } from './common'
// import {RootState} from '../store'
// import { fetcherror, fetchSuccess } from './common';
import * as responseCodes from '../../../_template/helpers/responseCodes'
import {ID} from '../../../_template/helpers'
import {FETCH_START, FETCH_SUCCESS, FETCH_ERROR} from '../actionTypes/index'
import formDataAxios from '../../modules/auth/core/formDataAxios'
import { UsersQueryResponse } from '../../pages/ListUsers/users-list/core/_models'

export const getAllFunction = (endpoint: string, actionType: string, filters: any) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const res: AxiosResponse<UsersQueryResponse> = await dataAxios.get(endpoint, {
        params: {...filters},
      })
      if (res.data?.code === responseCodes.NO_DATA_FOUND) {
        dispatch({
          type: actionType,
          payload: {data: [], totalPages: 1},
        })
      } else {
        dispatch({
          type: actionType,
          payload: res.data,
        })
      }
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_ERROR,
        payload: '',
      })
    }
  }
}
export const getOneFunctionById = (endpoint: string, actionType: string, id: ID) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({type: FETCH_START})
    try {
      const res: AxiosResponse<UsersQueryResponse> = await dataAxios.get(endpoint + '/' + id, {})

      if (res.data?.code === responseCodes.NO_DATA_FOUND) {
        dispatch({type: FETCH_SUCCESS})
        dispatch({
          type: actionType,
          payload: {},
        })
      } else if (res.data.data) {
        dispatch({type: FETCH_SUCCESS})
        dispatch({
          type: actionType,
          payload: res.data.data,
        })
      } else {
        dispatch({type: FETCH_SUCCESS})
        dispatch({
          type: actionType,
          payload: res.data,
        })
      }
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_ERROR,
        payload: '',
      })
    }
  }
}
export function addFunction(endpoint: string, body: any) {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({type: FETCH_START})
    return dataAxios.post(endpoint, body).then((res: AxiosResponse<UsersQueryResponse>) => {
      switch (res.data?.code) {
        case responseCodes.SUCCESSFULLY_CREATED:
          return dispatch({type: FETCH_SUCCESS, payload: 'Data is successflully created'})

        case responseCodes.USER_EMAIL_ALREADY_EXISTS:
          return dispatch({type: FETCH_ERROR, payload: 'Email alredy exists'})

        case responseCodes.USER_EXISTS_ERROR:
          return dispatch({type: FETCH_ERROR, payload: 'User alredy exists'})

        case responseCodes.USER_MOBILE_NUMBER_EXIST_ERROR:
          return dispatch({type: FETCH_ERROR, payload: 'Phone number alredy exists'})

        case responseCodes.MISSING_VALUE:
          return dispatch({type: FETCH_ERROR, payload: res.data?.message})

        default:
          return dispatch({type: FETCH_ERROR, payload: 'There is a problem please try again'})
      }
    })
  }
}
export function patchFunction(endpoint: string, body: any, id: ID) {
  return async (dispatch: Dispatch<Action>) => {
    // dispatch({
    //   type: actionTypes.GET_ALL_FUNCTION,
    //   payload: [],
    // })
    dispatch({type: FETCH_START})
    return dataAxios
      .patch(endpoint + `/${id}`, body)
      .then((res: AxiosResponse<UsersQueryResponse>) => {
        switch (res.data?.code) {
          case responseCodes.SUCCESSFULLY_CREATED:
            return dispatch({type: FETCH_SUCCESS, payload: 'Data is successflully updated'})

          case responseCodes.USER_EMAIL_ALREADY_EXISTS:
            return dispatch({type: FETCH_ERROR, payload: 'Email alredy exists'})

          case responseCodes.USER_EXISTS_ERROR:
            return dispatch({type: FETCH_ERROR, payload: 'User alredy exists'})

          case responseCodes.USER_MOBILE_NUMBER_EXIST_ERROR:
            return dispatch({type: FETCH_ERROR, payload: 'Phone number alredy exists'})

          default:
            return dispatch({type: FETCH_ERROR, payload: 'There is a problem please try again'})
        }
      })
  }
}
export function patchNotif(endpoint: string) {
  return async (dispatch: Dispatch<Action>) => {
    // dispatch({
    //   type: actionTypes.GET_ALL_FUNCTION,
    //   payload: [],
    // })
    dispatch({type: FETCH_START})
    return dataAxios.patch(endpoint, {}).then((res: AxiosResponse<UsersQueryResponse>) => {
      return true
    })
  }
}
export function formDatapatchFunction(endpoint: string, body: any, id: ID) {
  return async (dispatch: Dispatch<Action>) => {
    // dispatch({
    //   type: actionTypes.GET_ALL_FUNCTION,
    //   payload: [],
    // })
    dispatch({type: FETCH_START})
    return formDataAxios
      .patch(endpoint + `/${id}`, body)
      .then((res: AxiosResponse<UsersQueryResponse>) => {
        switch (res.data?.code) {
          case responseCodes.SUCCESSFULLY_CREATED:
            return dispatch({type: FETCH_SUCCESS, payload: 'Data is successflully updated'})

          case responseCodes.USER_EMAIL_ALREADY_EXISTS:
            return dispatch({type: FETCH_ERROR, payload: 'Email alredy exists'})

          case responseCodes.USER_EXISTS_ERROR:
            return dispatch({type: FETCH_ERROR, payload: 'User alredy exists'})

          case responseCodes.USER_MOBILE_NUMBER_EXIST_ERROR:
            return dispatch({type: FETCH_ERROR, payload: 'Phone number alredy exists'})

          default:
            return dispatch({type: FETCH_ERROR, payload: 'There is a problem please try again'})
        }
      })
  }
}
// export function removeUsers(usersList: IUsers) {
//   const action: UsersAction = {
//     type: actionTypes.DELETE_ONE,
//     payload: usersList,
//   }
//   return simulateHttpRequest(action)
// }
export function formDataAddFunction(endpoint: string, body: any) {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({type: FETCH_START})
    return formDataAxios.post(endpoint, body).then((res: AxiosResponse<UsersQueryResponse>) => {
      switch (res.data?.code) {
        case responseCodes.SUCCESSFULLY_CREATED:
          return dispatch({
            type: FETCH_SUCCESS,
            payload:
              'Data is successflully created,  user will recieve password in the given email',
          })

        case responseCodes.USER_EMAIL_ALREADY_EXISTS:
          return dispatch({type: FETCH_ERROR, payload: 'Email alredy exists'})

        case responseCodes.USER_EXISTS_ERROR:
          return dispatch({type: FETCH_ERROR, payload: 'User alredy exists'})

        case responseCodes.USER_MOBILE_NUMBER_EXIST_ERROR:
          return dispatch({type: FETCH_ERROR, payload: 'Phone number alredy exists'})

        default:
          return dispatch({type: FETCH_ERROR, payload: 'There is a problem please try again'})
      }
    })
  }
}
export function deleteFunction(endpoint: string, id: ID) {
  return async (dispatch: Dispatch<Action>) => {
    // dispatch({
    //   type: actionTypes.GET_ALL_FUNCTION,
    //   payload: [],
    // })
    dispatch({type: FETCH_START})
    return dataAxios.delete(endpoint + `/${id}`).then((res: AxiosResponse<UsersQueryResponse>) => {
      switch (res.data?.code) {
        case responseCodes.SUCCESSFULLY_DELETED:
          return dispatch({type: FETCH_SUCCESS, payload: 'Data is successflully deleted'})
        default:
          return dispatch({type: FETCH_ERROR, payload: 'There is a problem please try again'})
      }
    })
  }
}
