// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from '../../../../../../_template/layout/components/columns'
import {FormattedMessage} from 'react-intl'
import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'
import {CustomOrderStatus} from './customStatus'
import {AddressCell} from '../../../../../../_template/layout/components/columns/AddressCell'
import {FirstCell} from '../../../../../../_template/layout/components/columns/FirstCell'
import {MainOrderActionsCell} from '../../../../../../_template/layout/components/columns/SubscriptionsActionsCell'
import {Link} from 'react-router-dom'
import {FirstCellLink} from '../../../../../../_template/layout/components/columns/FirstCellLink'

const SubscriptionsColumns: ReadonlyArray<Column<IUserSubscription>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.USER' />}
        className='min-w-125px'
      />
    ),
    Cell: ({...props}) => {
      const {user_info} = props?.data[props.row.index]
      return (
        <div className='d-flex gap-1 '>
                  <img
          src={process.env.REACT_APP_API_URL + '/' + props.data[props.row.index]?.user_info?.avatar}
          alt="Avatar"
          className="rounded-circle me-2"
          style={{ width: '40px', height: '40px' }}
        />
          <div className='fw-bold '>{user_info?.firstname}</div>
          <div className='fw-bold'>{user_info?.lastname}</div>
          
        </div>
      )
    },

    id: 'user',
  },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='GORELLA.COMMON.TOTAL' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'totalAmount',
  //   Cell: ({...props}) => <div>{Number(props?.data[props.row.index]?.totalAmount).toFixed(2)}</div>,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='GORELLA.COMMON.PAYMENTMETHOD' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   accessor: 'paidThrough',
  //   // Cell: ({...props}) => <AddressCell order={props.data[props.row.index]} />,
  //   // accessor: 'customer',
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.COUNTRY' />}
        className='min-w-125px'
      />
    ),
    id: 'customer.address',
    Cell: ({...props}) => (
      <AddressCell country={props.data[props.row.index].user_info?.country} />
    ),
    // accessor: 'customer',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.USET_TYPE' />}
        className='min-w-125px'
      />
    ),
    id: 'type',

    Cell: ({...props}) => <>{props.data[props.row.index]?.user_info?.type} </>,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.SUBSCRIPTIONS' />}
        className='min-w-125px'
      />
    ),
    id: 'user_info.subscription.subscription_type_name',

    Cell: ({...props}) => (
      <>{props.data[props.row.index]?.subscription_type_name} </>
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.CREATIONDATE' />}
        className='min-w-80px'
      />
    ),
    id: 'createDate',
    Cell: ({...props}) => (
      <DateCell date={props?.data[props.row.index].start_date} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.EXPIRYDATE' />}
        className='min-w-80px'
      />
    ),
    id: 'expiryDate',
    Cell: ({...props}) => (
      <DateCell color='true' date={props.data[props.row.index]?.end_date} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.STATUS' />}
        className='min-w-125px'
      />
    ),
    id: 'status',
    Cell: ({...props}) => <CustomOrderStatus status={props.data[props.row.index]?.status }  expiryDate={props.data[props.row.index].end_date}/>,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        // title={<FormattedMessage id='MENU.ACTIONS' />}
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <MainOrderActionsCell id={props.data[props.row.index]?._id} />,
  },
]

export {SubscriptionsColumns}
