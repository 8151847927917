import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAppDispatch } from '../hooks';
import { getAllFunction } from '../redux/actions/commonCrud';
import { Actions } from '../../_template/helpers';
import { GET_ALL_ACTIONS, GET_ALL_STATUS } from '../redux/actionTypes';
import { deleteOrderStatu } from '../modules/auth/core/_requests';
import { useIntl } from 'react-intl'
import { fetchSuccess } from '../redux/actions/common';

const ActionsDeleteForm = ({ show, handleClose, id }: {
    handleClose: any;
    show: any;
    id: string;
}) => {

    const intl = useIntl()

    const dispatch = useAppDispatch()
    const handleDispatch = () => {
        dispatch(getAllFunction(Actions, GET_ALL_ACTIONS, {}))
    }
    const handleDelete = () => {
        deleteOrderStatu(id)
            .then(() => {
                dispatch(fetchSuccess("Data successfully deleted"))
                handleDispatch()
                handleClose();
            })
            .catch((err) => {
              
                alert('error deleting the order stat us')
            })
    }


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{intl.formatMessage({ id: 'GORELLA.COMMON.DELETE_ORDER_STATUS' })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{intl.formatMessage({ id: 'GORELLA.COMMON.THIS_ACTION_IS' })}<span className='text-danger fw-bold '>{" "}{intl.formatMessage({ id: 'GORELLA.COMMON.IRREVERSIBLE' })}</span>{intl.formatMessage({ id: 'GORELLA.COMMON.AREYOUSURE' })}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {intl.formatMessage({ id: 'GORELLA.COMMON.CANCEL' })}
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    {intl.formatMessage({ id: 'GORELLA.COMMON.DELETE' }).toUpperCase()}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ActionsDeleteForm    