import {ListViewProvider, useListView} from './core/ListViewProvider'
import {UserActivitiesListHeader} from './components/header/UserActivitiesListHeader'
import {ActivitiesByUSerTable} from './table/userActivitiesTable'
import {DriverEditModal} from './userActivities-edit-modal/DriverEditModal'
import InfoView from '../../../../_template/layout/components/infoView/index'
import {Tab, Tabs} from 'react-bootstrap'
import {PageTitle} from '../../../../_template/layout/core'
import {useIntl} from 'react-intl'
import {useState} from 'react'

import getBreadCrambs from '../../../routing/breadcramps/getBreadCrambs'
// import {useSelector} from 'react-redux'
import DocsCount from '../../../components/DocsCount'

interface state {
  status?: string | undefined
  role?: string | undefined
  page?: number | undefined
}
const UserActivitiesList = () => {
  const {itemIdForUpdate} = useListView()
  const [status, setStatus] = useState<state>({status: undefined, page: 1})
  const intl = useIntl()
  const [count, setCount] = useState(0)

  // const roles: Role | undefined = useSelector(({users}: {users: UsersState}) => {
  //   return users.roles.find((item) => item.name === 'ROLE_DRIVER')
  // })
  const handleSelect = (key: string | null) => {
    if (key === 'INACTIVE') setStatus({...status, status: 'INACTIVE', page: 1})
    else if (key === 'ACTIVE') setStatus({...status, status: 'ACTIVE', page: 1})
    else setStatus({...status, status: undefined})
  }
  return (
    <>
      <InfoView />
      <div
        className='d-flex justify-content-between'
        style={{marginTop: '-3.6%', marginBottom: '25px'}}
      >
        <div>
          <PageTitle breadcrumbs={getBreadCrambs(intl)}>
            {intl.formatMessage({id: 'MENU.USER_ACTIVITIES'})}
          </PageTitle>
        </div>
        <div>
          <UserActivitiesListHeader />
        </div>
      </div>
      {/* <Tabs id='controlled-tab-example' defaultActiveKey='All' onSelect={(k) => handleSelect(k)}>
        <Tab
          eventKey='All'
          title={
            <DocsCount
              showNumbers={true}
              count={count}
              title={intl.formatMessage({id: 'GORELLA.COMMON.ALL'})}
            />
          }
        >
          <ActivitiesByUSerTable setCount={setCount} eventKey='All' />
        </Tab>
      </Tabs> */}
      <ActivitiesByUSerTable setCount={setCount} eventKey='All' />

      {itemIdForUpdate !== undefined && <DriverEditModal />}
    </>
  )
}

const UserActivitiesListWrapper = () => (
  <ListViewProvider>
    <UserActivitiesList />
  </ListViewProvider>
)

export {UserActivitiesListWrapper}
