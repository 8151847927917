import { INotification } from "../../type";

export const notificationsData: INotification[] = [
  {
    _id: '6538ca093a9f06545bab77f6',
    title: 'New Subsctiption',

    isDriver: 0,
    message: 'miral HS added a new order',
    status: 0,
    reference: '1504-1698220553597',
    type: 3232,
    createDate: '2023-10-25T07:55:53.839Z',
  },
  {
    _id: '6537aa873a9f06545bab0b86',
    title: 'New Subsctiption',

    isDriver: 0,
    message: 'Sammz Berry added a new order',
    status: 0,
    reference: '1503-1698146950907',
    type: 3232,
    createDate: '2023-10-24T11:29:11.031Z',
  },
  {
    _id: '6537a9b93a9f06545bab0283',
    title: 'New Subsctiption',

    isDriver: 0,
    message: 'Sammz Berry added a new order',
    status: 0,
    reference: '1502-1698146745673',
    type: 3232,
    createDate: '2023-10-24T11:25:45.795Z',
  },
]
