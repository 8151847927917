import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_template/helpers'
import { IUserSubscription } from '../../../../type'
// import {Customer} from './_models'

const USER_URL = `/user`
// const GET_USERS_URL = `/users/query`

// const getUsers = (query: string): Promise<UsersQueryResponse> => {
//   return axios
//     .get(`${GET_USERS_URL}?${query}`)
//     .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
// }

const getUserById = (id: ID): Promise<IUserSubscription | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<IUserSubscription>>) => response.data)
    .then((response: Response<IUserSubscription>) => response.data)
}

// const createUser = (user: Customer): Promise<Customer | undefined> => {
//   return axios
//     .put(USER_URL, user)
//     .then((response: AxiosResponse<Response<Customer>>) => response.data)
//     .then((response: Response<Customer>) => response.data)
// }

// const updateUser = (user: Customer): Promise<Customer | undefined> => {
//   return axios
//     .post(`${USER_URL}/${user._id}`, user)
//     .then((response: AxiosResponse<Response<Customer>>) => response.data)
//     .then((response: Response<Customer>) => response.data)
// }

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => { })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

export { deleteUser, deleteSelectedUsers, getUserById }
