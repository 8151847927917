import {IntlShape} from 'react-intl'
import {PageLink} from '../../../_template/layout/core'

const getBreadCrambs = (intl: IntlShape, ...breadcrambs: PageLink[]): PageLink[] => [
  {
    title: intl.formatMessage({id: 'GORELLA.COMMON.DASHBOARD'}),
    path: '/dashboard',
    isSeparator: false,
    isActive: true,
  },
  ...breadcrambs,
]

export default getBreadCrambs
