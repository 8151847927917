import { FC } from 'react'
import Actionwrapper from './Actions-list/actionList'

const ActionPageWrapper: FC = () => {
  return (
    <>
      <Actionwrapper />
    </>
  )
}

export default ActionPageWrapper
