// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from '../../../../../../_template/layout/components/columns'

import {FormattedMessage} from 'react-intl'
import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'

import {RatingCell} from '../../../../../../_template/layout/components/columns/RatingCell'
import {RelayPointActionsCell} from '../../../../../../_template/layout/components/columns/RelayPointActionsCell'

const usersColumns: ReadonlyArray<Column<IUsers>> = [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.ACTIONS' />}
        className='min-w-125px'
      />
    ),
    id: 'action_info',
    accessor: 'action_info.name',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.FEEDBACK' />}
        className='min-w-125px'
      />
    ),
    id: 'feedback',
    accessor: 'feedback',
    Cell: ({...props}) =>
      props.data[props.row.index]?.value && (
        <RatingCell value={props.data[props.row.index].value} />
      ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.CREATIONDATE' />}
        className='min-w-80px'
      />
    ),
    id: 'createDate',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].date} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='MENU.ACTIONS' />}
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <RelayPointActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {usersColumns}
