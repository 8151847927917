/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl, FormattedMessage} from 'react-intl'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../../hooks'
import {getOneFunctionById} from '../../../../redux/actions/commonCrud'
import {PageTitle} from '../../../../../_template/layout/core'

import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
// import { useListView } from '../core/ListViewProvider'
import {Link, useParams} from 'react-router-dom'
import Loading from '../../../../../_template/layout/components/loading/Loading'
import {toAbsoluteUrl} from '../../../../../_template/helpers'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'
import moment from 'moment'
import {StatisticsDriver} from './statisticsDriver'
import {IUsers, UsersState} from '../../../../type'
import { StatisticsWidget2 } from '../../../dashboard/InfoBarWidget2'
import { Alert } from 'react-bootstrap'

export function UsersInfo() {
  const usersIcon ="/media/avatars/mask.png"
  const revnuIcon ="/media/avatars/wallet.png"
  const user: IUsers = useSelector(({users}: {users: UsersState}) => {
    return users.oneUser
  })
  const UserActivitiesQuota: any = useSelector(({users}: {users: UsersState}) => {
    return users.UserActivitiesQuota
  })
  const blankImg = toAbsoluteUrl('/media/avatars/png-avatar.png')
  let {id} = useParams()
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  useEffect(() => {
    setLoading(true)
    dispatch(getOneFunctionById(endpoints.userEndpoint, actionTypes.GET_ONE_USER_BY_ID, id))
    //dispatch(getAllFunction(endpoints.userRole, actionTypes.GET_ALL_USER_ROLES, {}))
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [dispatch, id])
  const intl = useIntl()
  return (
    <>
      {loading && <Loading />}

      <div className='d-flex justify-content-between flex-wrap'>
        <PageTitle
          breadcrumbs={getBreadCrambs(intl, {
            isActive: false,
            path: '/users',
            title: intl.formatMessage({id: 'MENU.USERS'}),
            isSeparator: false,
          })}
        >
          {intl.formatMessage({id: 'GORELLA.USERS.USERS_DETAILS'})}
        </PageTitle>
      </div>

      {user && (
        <div className='row bg-white rounded p-10'>
          <div className='row'>
            {/* begin::Image input */}
            <div className='w-auto'>
              {' '}
              <div
                className='image-input image-input-outline mx-auto'
                data-kt-image-input='true'
                style={{
                  backgroundImage: `url('${
                    user?.avatar ? process.env.REACT_APP_API_URL + '/' + user?.avatar : blankImg
                  }')`,
                  padding: '10px',
                  width: 'fit-content',
                  height: 'fit-content',
                  borderColor: '#c6c9cc42',
                  borderStyle: 'dashed',
                  borderRadius: '70px',
                }}
              >
                {/* begin::Preview existing avatar */}
                <div
                  className='image-input-wrapper w-110px h-110px p-5'
                  style={{
                    borderRadius: '70px',
                    backgroundImage: `url('${
                      user?.avatar ? process.env.REACT_APP_API_URL + '/' + user?.avatar : blankImg
                    }')`,
                  }}
                ></div>
                {/* end::Preview existing avatar */}
                {/* begin::Cancel */}
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  title='Cancel avatar'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
                {/* end::Cancel */}
              </div>
            </div>

            {/* end::Image input */}

            <div className='w-auto mt-8 mx-auto'>
              <h4 className='text-bluePlaceholder fs-6'>
                {' '}
                <FormattedMessage id='GORELLA.COMMON.PROFILE_DETAILS' />
              </h4>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'GORELLA.COMMON.NAME'})}
                value={`${user?.firstname + ' ' + user?.lastname}`}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'GORELLA.COMMON.EMAIL'})}
                value={user?.email}
              />
              {/* <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'GORELLA.COMMON.PHONENUMBER' })}
                value={user?.phoneNumber}
              /> */}
            </div>
            <div className='w-auto  mt-8 mx-auto'>
              <h4 className='text-bluePlaceholder fs-6'> 
                {' '}
                <FormattedMessage id='GORELLA.COMMON.COUNTRY_DETAILS' />
              </h4>
              {/* <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'GORELLA.COMMON.COUNTRY_LINE_1' })}
                value={user?.address?.addressLine1}
              /> */}
              {/* <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'GORELLA.COMMON.COUNTRY_LINE_2' })}
                value={user?.address?.addressLine2}
              /> */}
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'GORELLA.COMMON.CITY'})}
                value={user?.city}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'GORELLA.COMMON.COUNTRY'})}
                value={user?.country}
              />
              {/* <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'GORELLA.COMMON.STATE' })}
                value={user?.address?.state}
              /> */}
            </div>
            <div className='w-auto mt-8 mx-auto'>
              <h4 className='text-bluePlaceholder fs-6'>
                {' '}
                <FormattedMessage id='GORELLA.COMMON.STATUS_DETAILS' />
              </h4>
              {/* <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'GORELLA.COMMON.ROLE' })}
                value={roles.find((role) => role._id === user.role)?.name}
              /> */}
              <div className='d-flex fs-5 my-'>
                <div className='text-darkblue '>
                  {intl.formatMessage({id: 'GORELLA.COMMON.STATUS'})}
                </div>
                :{' '}
                <div className='mx-2'>
                  {' '}
                  {user?.is_verified === true ? (
                    <span className='badge badge-light-success fs-5 fw-bold '>
                      {' '}
                      {intl.formatMessage({id: 'GORELLA.COMMON.STATUS.ACTIVE'})}
                    </span>
                  ) : user?.is_verified === false ? (
                    <span className='badge badge-light-danger fs-5 fw-bold '>
                      {intl.formatMessage({id: 'GORELLA.COMMON.STATUS.INACTIVE'})}
                    </span>
                  ) : (
                    <span className='badge badge-light-info fs-5 fw-bold '>
                      {user?.is_verified}
                    </span>
                  )}{' '}
                </div>
              </div>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'GORELLA.COMMON.CREATEDAT'})}
                value={new Date(user?.createDate || new Date()).toLocaleDateString('en')}
              />
            </div>
            <div className='w-auto mt-8 mx-auto'>
              <h4 className='text-bluePlaceholder fs-6'>
                {' '}
                <FormattedMessage id='GORELLA.COMMON.SUBSCRIPTION_DETAILS' />
              </h4>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'GORELLA.COMMON.SUBSCRIPTION_DETAILS.CATEGORY'})}
                value={user.subscription?.subscription_type_name}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'GORELLA.COMMON.SUBSCRIPTION_DETAILS.START-DATE'})}
                value={moment(user.subscription?.start_date ?? user?.start_date).format(
                  'MM.DD.YYYY, HH:mm'
                )}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'GORELLA.COMMON.SUBSCRIPTION_DETAILS.END-DATE'})}
                value={moment(user.subscription?.end_date ?? user?.end_date).format(
                  'MM.DD.YYYY, HH:mm'
                )}
                // value={user.subscription?.end_date}
              />
            </div>
          </div>

          {/* <div>
            <h2>{intl.formatMessage({id: 'GORELLA.COMMON.COUNTRY'})}</h2>
            <div className='d-flex'>
          
            </div>
          </div> */}
        </div>
      )}

      <div className='d-flex bg-white p-5 mt-4  rounded  justify-content-between'>
        <div className='w-auto mt-8 p-10'>
          <div className='row g-5 g-xl-8'>
            <h4 className='text-bluePlaceholder fs-6'>
              <FormattedMessage id='GORELLA.COMMON.SUBSCRIPTION_QUOTA' />
            </h4>
            {UserActivitiesQuota?.map((item: any, i: number) => (
            
              <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-4 my-2' key={`statInfo` + i}>
                 
         
          <StatisticsWidget2
            className='card-xl-stretch mb-xl-8'
            avatar={revnuIcon}
            title={item.name}
            value={item.quantity}
            percentage={'item.percentage'}
          />
      
              </div>
            ))}
          </div>
        </div>
        <div className='col-16 col-md-2' > 
    <Link to={`/users/${id}/activities`} className='d-block'>
      <button
        className='btn btn-design-blue me-0 btn-active-light-primary fw-bold  px-6'
        
      >
        Go to user activities
      </button>
    </Link>
  </div>
      </div>
    </>
  )
}

const HorizontalAttributeComponent = ({
  attr = 'Test',
  value = 'Test',
}: {
  attr?: string | undefined
  value?: string | undefined
}) => (
  <div className='d-flex fs-5 my-auto'>
    <div className='form-subtitle fs-6 text-darkblue'>{attr}</div>: <div className='mx-2 fs-6 text-bluePlaceholder'> {value}</div>
  </div>
)
