// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from '../../../../../../_template/layout/components/columns'

import {User} from '../../core/_models'
import {FormattedMessage} from 'react-intl'
import {RelayPointActionsCell} from '../../../../../../_template/layout/components/columns/RelayPointActionsCell'
import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'
import {RatingCell} from '../../../../../../_template/layout/components/columns/RatingCell'
import {Link} from 'react-router-dom'
import {date} from 'yup'
import {value} from '../../../../../modules/shared/actionTypes'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.USER' />}
        className='min-w-125px'
      />
    ),
    id: 'username',
    Cell: ({...props}) => (
      <div className="d-flex align-items-center">
      {props.data[props.row.index]?.user_info?.avatar && (
        <img
          src={process.env.REACT_APP_API_URL + '/' + props.data[props.row.index]?.user_info?.avatar}
          alt="Avatar"
          className="rounded-circle me-2"
          style={{ width: '40px', height: '40px' }}
        />
      )}
      <Link className='menu-link text-darkblue fs-8 fw-bolder ' to={`/upload-report/${props.data[props.row.index]?._id}`}>
        {props.data[props.row.index].user_info?.firstname +
          ' ' +
          props.data[props.row.index].user_info?.lastname}
      </Link>
    </div>
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.ACTIONS'/>}
        className='min-w-125px '
      />
    ),
    id: 'action_info',
    accessor: 'action_info.name',  
    Cell: ({value}) => <span className='fs-8'>{value}</span>
    // Cell: ({...props}) => <TrueOrFalseCell value={props.data[props.row.index].action_info} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='GORELLA.COMMON.RESIZE_OPTION' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'removeBg',
  //   accessor: 'resizeOption',
  //   Cell: ({...props}) => <TrueOrFalseCell value={props.data[props.row.index].resizeOption} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='GORELLA.COMMON.RESIZE_OPTION' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'resizeOption',
  //   accessor: 'resizeOption',
  //   Cell: ({...props}) => <TrueOrFalseCell value={props.data[props.row.index].resizeOption} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='GORELLA.COMMON.IMPROVE_OPTION' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'improveOption',
  //   accessor: 'resizeOption',
  //   Cell: ({...props}) => <TrueOrFalseCell value={props.data[props.row.index].resizeOption} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='GORELLA.COMMON.STATUS' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'status',
  //   Cell: ({...props}) => (
  //     <StatusDownloaded
  //       type='driver'
  //       id={props.data[props.row.index]._id}
  //       two_steps={props.data[props.row.index].statusCode}
  //     />
  //   ),
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='GORELLA.COMMON.FEEDBACK' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'feedback',
  //   accessor: 'feedback',
  //   Cell: ({...props}) => <RatingCell value={props.data[props.row.index].value} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.CREATIONDATE' />}
        className='min-w-80px'
      />
    ),
    id: 'createDate',
    Cell: ({...props}) => <DateCell  

    date={props.data[props.row.index].date}  />,
    
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        // title={<FormattedMessage id='MENU.ACTIONS' />}
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <RelayPointActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {usersColumns}
