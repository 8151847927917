import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {Formik, Form} from 'formik'
import BootstrapForm from 'react-bootstrap/Form'
import {object, string, number} from 'yup'
import {patchDriverArea, postDriverArea} from '../../../../../modules/auth/core/_requests'
import {fetchSuccess, fetcherror} from '../../../../../redux/actions/common'
import {useAppDispatch} from '../../../../../hooks'
import {getAllFunction, getOneFunctionById} from '../../../../../redux/actions/commonCrud'
import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../redux/actionTypes'
import {GET_ALL_ACTIONS, GET_ONE_PLATFORM_ACTIVITY} from '../../../../../redux/actionTypes'
import {useSelector} from 'react-redux'
import InfoView from '../../../../../../_template/layout/components/infoView'
import {MessageView} from '../../../../../../_template/layout/components/messageView'
import {CommonState, ConfigState, IPlatformActivity} from '../../../../../type'

//const googlePlaceKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PLACE || ''

// interface initialValuesT {
//     name: string;
//     city: string;
//     description: string;
//     radius: number;
//     latitude: number;
//     longitude: number;
// }

const ActionsForm = ({show, handleClose, id}: {handleClose: any; show: any; id?: string}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const commonState: CommonState = useSelector(({common}: {common: CommonState}) => common)

  const showMessage = () => {
    return <MessageView variant='alert-success' message='{commonState.message.toString()}' />
  }
  const handleDispatch = () => {
    dispatch(getAllFunction(endpoints.Actions, GET_ALL_ACTIONS, {}))
  }

  useEffect(() => {
    if (id) {
      dispatch(getOneFunctionById(endpoints.Actions, GET_ONE_PLATFORM_ACTIVITY, id))
    }
  }, [id])

  let oneActivity = useSelector(({config}: {config: ConfigState}) => config.oneActivity)
  oneActivity = id ? oneActivity : undefined

  const validationSchema = object().shape({
    name: string().required(intl.formatMessage({id: 'AUTH.GENERAL.NAME_IS_REQUIRED'})),
    code: string().required(intl.formatMessage({id: 'AUTH.GENERAL.CODE_IS_REQUIRED'})),
    description: string().required(
      intl.formatMessage({id: 'AUTH.GENERAL.DESCRIPTION_IS_REQUIRED'})
    ),
  })

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: oneActivity?.name || '',
        code: oneActivity?.code || '',
        description: oneActivity?.description || '',
      }}
      onSubmit={(values: any, actions: any) => {
        if (id) {
          // Edit
          const changedValues = Object.keys(values).reduce((acc: {[key: string]: any}, key) => {
            if (oneActivity && key in oneActivity) {
              const activity = oneActivity as IPlatformActivity
              if (values[key] !== (activity as any)[key]) {
                acc[key] = values[key]
              }
            }
            return acc
          }, {})

          if (Object.keys(changedValues).length === 0) {
            // No changes
            alert('No changes made')
            return
          }

          patchDriverArea(changedValues, id)
            .then(({data}) => {
              if (data.code === '2000') {
                dispatch(fetchSuccess('Data successfully updated'))

                return
              } else if (data.code === '2001') {
                dispatch(fetcherror('Error while updating data, please check your values'))
              }
            })
            .catch((err) => {
              dispatch(fetcherror(err.message))
            })
        } else {
          // Create
          postDriverArea({...values, status: 1})
            .then(({data}) => {
              if (data.code === '2000') {
                dispatch(fetchSuccess('Data successfully created'))
                dispatch(getAllFunction(endpoints.Actions, actionTypes.GET_ALL_ACTIONS, {}))

                return
              } else if (data.code === '2001') {
                dispatch(fetcherror('Error while adding data, please check your values'))
              }
            })
            .catch((err) => {
              dispatch(fetcherror(err.message))
            })
        }
        handleDispatch()
        handleClose()
        actions.resetForm()
      }}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({values, getFieldProps, handleSubmit, errors, isSubmitting, setFieldValue}) => (
        <Modal
          style={{
            zIndex: 10000,
          }}
          show={show}
          onHide={handleClose}
        >
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title className='fs-7'>
                {intl.formatMessage({
                  id: id
                    ? 'GORELLA.COMMON.EDIT_PLATFORM_ACTION'
                    : 'GORELLA.COMMON.ADD_PLATFORM_ACTION',
                })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* end::Actions */}
              <BootstrapForm>
                <BootstrapForm.Group className='mb-3 ' controlId='name'>
                  <BootstrapForm.Label className='text-darkblue fs-8'>
                    {intl.formatMessage({id: 'GORELLA.COMMON.NAME'})}
                  </BootstrapForm.Label>
                  <BootstrapForm.Control className='fs-7'
                    {...getFieldProps('name')}
                    value={values.name}
                    name='name'
                  />
                  {errors?.name && (
                    <BootstrapForm.Text className='text-danger fs-8'>
                      {errors?.name}
                    </BootstrapForm.Text>
                  )}
                  {/*  */}
                </BootstrapForm.Group>
                <BootstrapForm.Group className='mb-3' controlId='code'>
                  <BootstrapForm.Label className='text-darkblue fs-8'>
                    {intl.formatMessage({id: 'GORELLA.COMMON.CODE'})}
                  </BootstrapForm.Label>
                  <BootstrapForm.Control className='fs-7'
                    {...getFieldProps('code')}
                    value={values.code}
                    name='code'
                  />
                  {errors?.code && (
                    <BootstrapForm.Text className='text-danger fs-8'>
                      {errors?.code}
                    </BootstrapForm.Text>
                  )}
                  {/*  */}
                </BootstrapForm.Group>

                <BootstrapForm.Group className='mb-3' controlId='code'>
                  <BootstrapForm.Label className='text-darkblue fs-8'>
                    {intl.formatMessage({id: 'GORELLA.COMMON.DESCRIPTION'})}
                  </BootstrapForm.Label>
                  <BootstrapForm.Control className='fs-7'
                    {...getFieldProps('description')}
                    value={values.description}
                    name='description'
                    type='number'
                    as='textarea'
                    rows={3}
                  />
                  {errors?.description && (
                    <BootstrapForm.Text className='text-danger fs-8'>
                      {errors?.description}
                    </BootstrapForm.Text>
                  )}
                </BootstrapForm.Group>
              </BootstrapForm>
            </Modal.Body>
            <Modal.Footer>
              <button className='btn btn-lg btn-design-blue btn-light btn-active-light-primary  fs-6' onClick={handleClose}>
                {intl.formatMessage({id: 'GORELLA.COMMON.CANCEL'})}
              </button>
              <button disabled={isSubmitting} type='submit' className='btn btn-lg btn-design-blue btn-active-light-primary fs-6'>
                {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              </button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

export default ActionsForm
