import { useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_template/helpers';
import { useLang } from '../../../_template/i18n/Metronici18n';

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.height = '100%';
    }
    return () => {
      if (root) {
        root.style.height = 'auto';
      }
    };
  }, []);
  const locale: string = useLang();

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid overflow-hidden' style={{backgroundColor:"white"}}>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-100 p-0 p-lg-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-column flex-center flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='d-flex flex-center flex-column flex-lg-row-fluid '>
            <img src={toAbsoluteUrl('/media/logos/a.png')} alt='logo' />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div className='d-flex flex-lg-row-fluid w-100 bgi-size-cover bgi-position-center order-1 order-lg-2'>
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Outlet />
          <Link to='/' className='mb-12'></Link>
          {/* end::Logo */}

          {/* begin::Title */}
          {/* <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>ALJAMA-MARKET DASHBOARD </h1> */}
          {/* end::Title */}

          {/* begin::Text */}
          {/* <div className='text-white fs-base text-center'>
            In this kind of post,{' '}
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the blogger
            </a>
            introduces a person they’ve interviewed <br /> and provides some background information
            about
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the interviewee
            </a>
            and their <br /> work following this is a transcript of the interview.
          </div> */}
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  );
};

export { AuthLayout };
