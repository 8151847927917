import { useState } from 'react'
import { KTSVG } from '../../../../../../../_template/helpers'
import ActionsForm from '../../Actions-edit-modal/ActionsForm'

const DriverAreaListToolbar = () => {
  // const locale: string = useLang()
  const [show, setShow] = useState(false)
  // const [submitting, setSubmitting] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)


  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <Export dataType='users' /> */}

      {/* begin::Add user */}
      <button onClick={handleShow} type='button' className='btn btn-icon'>
        <img
          src='/media/icons/duotune/arrows/add.png'
           style={{paddingRight: '3rem'}}
        />
      </button>
      {<ActionsForm handleClose={handleClose} show={show} />}
    </div>
  )
}

export { DriverAreaListToolbar }
