/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, FC} from 'react'
import {initialQueryState, KTSVG, useDebounce} from '../../../../../../_template/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {MenuComponent} from '../../../../../../_template/assets/ts/components'
import {useLang} from '../../../../../../_template/i18n/Metronici18n'
import {useIntl} from 'react-intl'
import {useAppDispatch} from '../../../../../hooks'
import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../redux/actionTypes'
import {getAllFunction} from '../../../../../redux/actions/commonCrud'
const REACT_APP_ROLE_DRIVER = process.env.REACT_APP_ROLE_DRIVER || ''

type values = {
  role?: string | undefined
  phoneNumber?: string | undefined
  status?: string | undefined
  firstname?: string | undefined
  lastname?: string | undefined
  email?: string | undefined
}
type Props = {
  eventKey: string | undefined
  handleDispatch: () => any
  values: values | undefined
  handleChangeValues: (e: any) => any
  resetData: (e: any) => any
}

const UserActivitiesSearchComponent: FC<Props> = ({
  values,
  eventKey,
  handleChangeValues,
  handleDispatch,
  resetData,
}) => {
  const locale: string = useLang()
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm)
  const intl = useIntl()
  const dispatch = useAppDispatch()

  // const dispatch = useAppDispatch()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // Effect for API call
  useEffect(
    () => {
      // if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      //   // dispatch(
      //   //   getAllFunction(endpoints.userEndpoint, actionTypes.GET_ALL_USERS, {
      //   //     ...values,
      //   //     searchUser: searchTerm,
      //   //     role: REACT_APP_ROLE_DRIVER,
      //   //   })
      //   // )
      // }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex'>
        <div
          style={{
            position: 'relative',
          }}
          className='d-flex align-items-center position-relative my-0 w-100'
        >
          {' '}
          {/* <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute mx-5'
          /> */}
          {/* <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control px-14'
            placeholder={intl.formatMessage({
              id: 'GORELLA.USER_ACTIVITIES.INPUT_SEARCH_PLACEHOLDER',
            })}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          /> */}
          <button
            data-kt-menu-trigger='click'
            data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
            type='button'
            style={{
              position: 'absolute',
              right: locale !== 'ar' ? 0 : undefined,
              left: locale === 'ar' ? 0 : undefined,
              border: 0,
              zIndex: 5,
            }}
            className='btn btn-icon'
          >
            {/* <img src='/media/icons/duotune/print/Filter.png'/>{' '} */}
          </button>
          {/* begin::SubMenu */}
          <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
            {/* begin::Header */}
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>
                {intl.formatMessage({id: 'GORELLA.COMMON.FILTER_OPTIONS'})}
              </div>
            </div>
            {/* end::Header */}

            {/* begin::Separator */}
            <div className='separator border-gray-200'></div>
            {/* end::Separator */}

            {/* begin::Content */}
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              {/* begin::Input group */}

              {/* end::Input group */}

              {/* begin::Input group */}
              {/* <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({ id: 'GORELLA.COMMON.FIRST_NAME' })}
                </label>
                <input
                  type='text'
                  className='form-control rounded form-control-sm form-control-solid  ps-10'
                  name='firstname'
                  value={values?.firstname}
                  onChange={handleChangeValues}
                  placeholder={intl.formatMessage({ id: 'GORELLA.COMMON.FIRST_NAME' })}
                />
              </div> */}
              {/* end::Input group */}
              {/* begin::Input group */}
              {/* <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({ id: 'GORELLA.COMMON.LAST_NAME' })}
                </label>
                <input
                  type='text'
                  className='form-control rounded form-control-sm form-control-solid  ps-10'
                  name='lastname'
                  value={values?.lastname}
                  onChange={handleChangeValues}
                  placeholder={intl.formatMessage({ id: 'GORELLA.COMMON.LAST_NAME' })}
                />
              </div> */}
              {/* end::Input group */}
              {/* begin::Input group */}
              {/* <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({ id: 'GORELLA.COMMON.PHONENUMBER' })}
                </label>
                <input
                  type='text'
                  className='form-control rounded form-control-sm form-control-solid  ps-10'
                  name='phoneNumber'
                  value={values?.phoneNumber}
                  onChange={handleChangeValues}
                  placeholder={intl.formatMessage({ id: 'GORELLA.COMMON.PHONENUMBER' })}
                />
              </div> */}
              {/* end::Input group */}
              {/* begin::Actions */}
              {/* <div className='d-flex justify-content-between'>
                <button
                  type='button'
                  //disabled={isLoading}
                  onClick={resetData}
                  className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                  data-kt-menu-dismiss='false'
                  data-kt-user-table-filter='reset'
                >
                  {intl.formatMessage({ id: 'GORELLA.COMMON.RESET' })}
                </button>
                <button
                  // disabled={isLoading}
                  type='button'
                  onClick={() => handleDispatch()}
                  className='btn btn-design fw-bold px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                >
                  {intl.formatMessage({ id: 'GORELLA.COMMON.APPLY' })}
                </button>
              </div> */}
              {/* end::Actions */}
            </div>
            {/* end::Content */}
          </div>
          {/* end::SubMenu */}
        </div>
      </div>

      {/* end::Search */}
    </div>
  )
}

export {UserActivitiesSearchComponent}
