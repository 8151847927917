/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()
 const baseurl="/media/icons/duotune/art/"
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon={baseurl+'dashboard.png'}
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon={baseurl+'dashboard.png'}
      />

      <SidebarMenuItem
        to='/upload-report'
        icon={baseurl+'Chart.png'}
        title={intl.formatMessage({id: 'MENU.USER.ACTIVITIES'})}
        fontIcon={baseurl+'useractivity.png'}
      />
      <SidebarMenuItem
        to='/users'
        icon={baseurl+'users.png'}
        title={intl.formatMessage({id: 'MENU.USERS'})}
        fontIcon={baseurl+'users.png'}
      />
      <SidebarMenuItem
        to='/subscriptions'
        icon={baseurl+'Chart.png'}
        title={intl.formatMessage({id: 'MENU.SUBSCRIPTIONS'})}
        fontIcon='/media/icons/duotune/art/Usersubscription.png'
      />

      <SidebarMenuItem
        to='/configutation/categories'
        title={intl.formatMessage({id: 'MENU.SUBSCRIPTION_CATEGORY'})}
        fontIcon={baseurl+'subsplan.png'}
        icon={baseurl+'subsplan.png'}
      />

      {/* <SidebarMenuItem
        to='/configutation/actions'
        title={intl.formatMessage({id: 'MENU.PLATFORM_ACTIVITIES'})}
        fontIcon={baseurl+'action.png'}
        icon={baseurl+'action.png'}
      /> */}


      {/* <SidebarMenuItem
        to='#'
        title={intl.formatMessage({id: 'MENU.SUPPORT'})}
        fontIcon={baseurl+'support.png'}
        icon={baseurl+'support.png'}
      /> */}
        {/* <SidebarMenuItem
        to='#'
        title={intl.formatMessage({id: 'MENU.CONTACT'})}
        fontIcon={baseurl+'Chat.png'}
        icon={baseurl+'Chat.png'}
      /> */}
        <SidebarMenuItem
          to='/crafted/pages/profile/overview'
          title={intl.formatMessage({id: 'MENU.SETTINGS'})}
          fontIcon={baseurl+'config.png'}
          icon={baseurl+'config.png'}
        />

      {/* BEGIN: SidebarMenuItemWithSub */}
      {/* <SidebarMenuItemWithSub
        to='/configuration'
        title={intl.formatMessage({id: 'MENU.CONFIG'})}
        fontIcon={baseurl+'config.png'}
        icon={baseurl+'config.png'}
      >
        <SidebarMenuItem
          to='/crafted/pages/profile/overview'
          title={intl.formatMessage({id: 'MENU.SETTINGS'})}
          hasBullet={false}
        />
      </SidebarMenuItemWithSub> */}
    </>
  )
}

export {SidebarMenuMain}
