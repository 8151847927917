import {ListViewProvider} from './core/ListViewProvider'
import {SubscriptionsListHeader} from './components/header/SubscriptionsListHeader'
import {SubsctiptionsTable} from './table/SubscriptionsTable'
import {useAppDispatch} from '../../../hooks'
import {useEffect, useState} from 'react'

import InfoView from '../../../../_template/layout/components/infoView'
import * as endpoints from '../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../redux/actionTypes'
import {Tab, Tabs} from 'react-bootstrap'
import {PageTitle} from '../../../../_template/layout/core'
import {useIntl} from 'react-intl'
import getBreadCrambs from '../../../routing/breadcramps/getBreadCrambs'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import DocsCount from '../../../components/DocsCount'
import {getAllFunction} from '../../../redux/actions/commonCrud'

interface state {
  status?: string | undefined
  role?: string | undefined
  page?: number | undefined
}
const SubscriptionsList = () => {
  // const {itemIdForUpdate} = useListView()
  const [status, setStatus] = useState<state>({status: undefined})
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [count, setCount] = useState(0)

  useEffect(() => {
    dispatch(getAllFunction(endpoints.userEndpoint, actionTypes.GET_ALL_USERS, {per_page: 100}))
    dispatch(
      getAllFunction(endpoints.SubscriptionPlan, actionTypes.GET_ALL_SUBSCRIPTIONS_CATEGORY, {})
    )

    return () => {
      dispatch({type: actionTypes.GET_ALL_USERS, payload: {data: [], totalPages: 1}})
      dispatch({type: actionTypes.GET_ALL_USER_SUBSCRIPTIONS, payload: {data: [], totalPages: 1}})
      dispatch({
        type: actionTypes.GET_ALL_SUBSCRIPTIONS_CATEGORY,
        payload: {data: [], totalPages: 1},
      })
    }
  }, [dispatch])
  return (
    <>
      <div
        className='d-flex justify-content-between'
        style={{marginTop: '-3.5%', marginBottom: '25px'}}
      >
        <div>
          <PageTitle breadcrumbs={getBreadCrambs(intl)}>
            {intl.formatMessage({id: 'MENU.SUBSCRIPTIONS'})}
          </PageTitle>
          <InfoView />
        </div>

        <div>
          <SubscriptionsListHeader />
        </div>
      </div>

      {/* <Tabs id='controlled-tab-example' defaultActiveKey='All'>
        <Tab
          className='nav-item'
          eventKey='All'
          title={
            <DocsCount
              showNumbers={true}
              count={count}
              title={intl.formatMessage({id: 'GORELLA.COMMON.ALL'})}
            />
          }
        >
          <SubsctiptionsTable setCount={setCount} />
        </Tab>
      </Tabs> */}

      <SubsctiptionsTable setCount={setCount} />
      
    </>
  )
}

const SubscriptionsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SubscriptionsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SubscriptionsListWrapper}
