export const statisticsData = {

  data: {
    sales: {
      total: {
        amount: 124283719.71,
        count: 1504,
      },
      currentWeek: {
        amount: 554,
        count: 4,
      },
      lastWeek: {
        amount: 1413.2,
        count: 15,
      },
    },
    orders: {
      total: {
        amount: 124283719.71,
        count: 1504,
      },
      status: [
        {
          amount: 185073.95,
          count: 81,
          status: {
            _id: '64131fdcc1ab747f2fc62404',
            name: 'Premium',
            code: '20',
            description: 'Order is assigned to a driver',
            createdBy: '63f78082fc1c6f259ad7b323',
            createDate: '2023-03-16T13:55:40.955Z',
            updateDate: '2023-03-16T13:55:40.955Z',
            __v: 0,
            updatedBy: '641c4b083f5e54d4b6d8bff0',
            color: '#dfe24a',
            descriptionAr: 'تم تعيين الطلب',
            nameAr: 'تم تعيين الطلب',
          },
        },
      
        {
          amount: 117432.61,
          count: 57,
          status: {
            _id: '643961951ecf390ae3a39366',
            name: 'standard',
            code: '100',
            description: 'sstandard',
            color: '#e66d20',
            icon: './images/Group 41-79f1.png',
            createdBy: '641c4b083f5e54d4b6d8bff0',
            createDate: '2023-04-14T14:22:13.419Z',
            updateDate: '2023-04-14T14:22:13.419Z',
            __v: 0,
            updatedBy: '641c4b083f5e54d4b6d8bff0',
            descriptionAr: 'تم التوصيل',
            nameAr: 'تم التوصيل',
          },
        },
        {
          amount: 123969740.69,
          count: 1247,
          status: {
            _id: '643959601ecf390ae3a39109',
            name: 'Free',
            code: '10',
            description: 'Order is waiting to be accepted',
            color: '#50e3c2',
            icon: null,
            createdBy: '641c4b083f5e54d4b6d8bff0',
            createDate: '2023-04-14T13:47:12.429Z',
            updateDate: '2023-04-14T13:47:12.429Z',
            __v: 0,
            updatedBy: '641c4b083f5e54d4b6d8bff0',
            descriptionAr: 'الطلب في انتظار قبوله',
            nameAr: 'في انتظار',
          },
        },
      ],
      daily: {
        amount: 42,
        count: 2,
      },
      weekly: {
        amount: 1932.2,
        count: 17,
      },
      montly: {
        amount: 2863.4,
        count: 47,
      },
    },
    drivers: 32,
    customers: 77,
  },
}
