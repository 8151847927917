import React, {FC} from 'react'
import {PageTitle} from '../../../_template/layout/core'
// import {BuilderPage} from './BuilderPage'
import {useIntl} from 'react-intl'
import {SubscriptionsListWrapper} from './subscriptions-list/SubscriptionsList'

const SubscriptionsPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MAIN.ORDERS'})}</PageTitle>
      <SubscriptionsListWrapper />
    </>
  )
}

export default SubscriptionsPageWrapper
