import React from 'react'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import UsersTable from './table/UsersTable'

import {UserEditModal} from './user-edit-modal/UserEditModal'
import InfoView from '../../../../_template/layout/components/infoView/index'
import * as endpoints from '../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../redux/actionTypes'

import {Tab, Tabs} from 'react-bootstrap'
import {PageTitle} from '../../../../_template/layout/core'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useAppDispatch} from '../../../hooks'
import {getAllFunction} from '../../../redux/actions/commonCrud'
import getBreadCrambs from '../../../routing/breadcramps/getBreadCrambs'
import DocsCount from '../../../components/DocsCount'

interface state {
  status?: string | undefined
  role?: string | undefined
  page?: number | undefined
}
const UsersList = () => {
  const {itemIdForUpdate} = useListView()
  const [status, setStatus] = useState<state>({status: undefined, page: 1})
  const [count, setCount] = useState(0)

  const intl = useIntl()
  const handleSelect = (key: string | null) => {
    if (key) {
      localStorage.setItem('status', key)
    } else {
      localStorage.removeItem('status')
    }
    if (key === 'INACTIVE') setStatus({...status, status: 'INACTIVE', page: 1})
    else if (key === 'ACTIVE') setStatus({...status, status: 'ACTIVE', page: 1})
    else setStatus({...status, status: undefined})
  }
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getAllFunction(endpoints.userEndpoint, actionTypes.GET_ALL_USERS, {}))
    dispatch(
      getAllFunction(endpoints.SubscriptionPlan, actionTypes.GET_ALL_SUBSCRIPTIONS_CATEGORY, {}))
    
  }, [dispatch, status])
  useEffect(() => {
    // dispatch(getAllFunction(endpoints.userRole, actionTypes.GET_ALL_USER_ROLES, {}))

    return () => {
      // dispatch({type: actionTypes.GET_ALL_USER_ROLES, payload: []})

      dispatch({type: actionTypes.GET_ALL_USERS, payload: {data: [], totalPages: 1}})
    }
  }, [dispatch])
  return (
    <>
      <InfoView />
      <div
        className='d-flex justify-content-between'
        style={{marginTop: '-3.5%', marginBottom: '25px'}}
      >
        <div>
          <PageTitle breadcrumbs={getBreadCrambs(intl)}>
            {intl.formatMessage({id: 'MENU.USERS'})}
          </PageTitle>
        </div>
        {/* <div>
          <UsersListHeader />
        </div> */}
      </div>
      {/* <KTCard> */}
      {/* <Tabs id='controlled-tab-example' onSelect={(k) => handleSelect(k)}>
        <Tab
        
          eventKey='All'
          title={
            <DocsCount
              showNumbers={!status.status}
              count={count}
              title={intl.formatMessage({id: 'GORELLA.COMMON.ALL'})}
            />
          }
        >
          <UsersTable setCount={setCount} eventKey='All' />
        </Tab>

      </Tabs> */}
       <UsersTable setCount={setCount} eventKey='All' />

      {/* </KTCard> */}
      {itemIdForUpdate !== undefined && <UserEditModal />}
    </>
  )
}

const UsersListWrapper = () => (
  <ListViewProvider>
    <UsersList />
  </ListViewProvider>
)

export {UsersListWrapper}
