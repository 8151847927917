export const sellerEndpoint = '/api/sellers'
export const userEndpoint = '/user'
export const customerEndpoint = '/api/customers'
export const userActivitiesEndpoint = '/user_action'

export const SubscriptionPlan = '/subscription_plan'
export const userRole = '/api/roles'
export const UserByCountry = '/user/user_by_country'
export const ActionPerDay = '/actions_per_day'
export const SubscriptionRevenue = '/calculate_subscription_data'

export const allNotifications = '/api/notifications'

export const subscriptionsEndpoint = '/subscription_plan'
export const Actions = '/actions'

export const accountDetails = 'profile'

export const listUsersSubscriptions = '/user/subscription'
