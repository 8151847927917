import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageTitle } from '../../../../../../_template/layout/core';
import getBreadCrambs from '../../../../../routing/breadcramps/getBreadCrambs';
import { useIntl } from 'react-intl'
//import { DriverAreas } from '../../../../../../_template/helpers';
import { getOneFunctionById } from '../../../../../redux/actions/commonCrud';
import { useAppDispatch } from '../../../../../hooks';
import { useSelector } from 'react-redux';
import ActionsForm from '../Actions-edit-modal/ActionsForm';
import { ConfigState } from '../../../../../type';

const Index = () => {
    let { id } = useParams();
    const intl = useIntl();
    const [show, setShow] = useState(false)
    // const [submitting, setSubmitting] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const dispatch = useAppDispatch()
    useEffect(() => {
        //dispatch(getOneFunctionById(DriverAreas, GET_ONE_PLATFORM_ACTIVITY, id))
    }, [])
    const oneActivity = useSelector(({ config }: { config: ConfigState }) => config.oneActivity)

    // const oneActivity = {
    //     _id: 1,
    //     label: "طرابلس المركز",
    //     description: "lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores aperiam, dignissimos ex odio maxime obcaecati, laudantium tenetur quo deserunt aspernatur ipsum, accusantium error ratione sunt nihil cupiditate quaerat harum animi.",
    //     city: "طرابلس",
    //     createDate: new Date(),
    //     radius: 2814.17,
    //     latitude: 32.89678995422581,
    //     longitude: 13.196403980255129
    // };

    return (
        <>
            {/* {loading && <UsersListLoading />} */}
            <PageTitle breadcrumbs={getBreadCrambs(intl, {
                isActive: false,
                path: '/configutation/actions',
                title: intl.formatMessage({ id: 'GORELLA.PLATFORM_ACTIVITIES' }),
                isSeparator: false,
            })}>
                {intl.formatMessage({ id: 'GORELLA.ORDER_STATUS.VIEW_DRIVER_AREA' })}
            </PageTitle>
            <div className='card'>
                <div className='card-body mb-5 mb-xl-10' id='kt_profile_details_view'>
                    <div className='card-header'>
                        <div className='card-title mb-3 d-flex flex-row justify-content-between'>
                            <h1 className='m-0 w-75' style={{ color: '#e9a800' }}>
                                {oneActivity?.name}
                                <h6 className='mt-3' style={{ color: '#e9a800' }}>
                                    {oneActivity?.description}
                                </h6>
                            </h1>
                            <button onClick={handleShow} className='btn btn-design-blue my-auto'>{intl.formatMessage({ id: 'GORELLA.COMMON.EDITDRIVERAREA' })}</button>
                        </div>
                    </div>
                    <div className='w-md-100 w-sm-100 p-10'>
                        <h2 className='mb-5'>{intl.formatMessage({ id: 'GORELLA.COMMON.COORDINATES' })}</h2>
                    </div>
                    {<ActionsForm handleClose={handleClose} id={id} show={show} />}
                </div>
            </div>
        </>
    )
}

export default Index