// @ts-nocheck
import {FC} from 'react'
import {ColumnInstance} from 'react-table'
// import {User} from '../../core/_models'

type Props = {
  column: ColumnInstance<IUsers>
}

const CustomHeaderColumn: FC<Props> = ({column}) => {
  if (column.Header !== 'ACTIONS') {
    console.log('+++' + JSON.stringify(column.Header))
    return (
      <>
        {column.Header && typeof column.Header === 'string' ? (
          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
        ) : (
          column.render('Header')
        )}
      </>
    )
  }

  return null
}

export {CustomHeaderColumn}
