import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { useQueryResponseLoading } from '../core/QueryResponseProvider'
import { SubscriptionsColumns } from './columns/_columns'
import * as actionTypes from '../../../../redux/actionTypes'
import { KTCard, KTCardBody } from '../../../../../_template/helpers'
import { useSelector } from 'react-redux'
import { CustomHeaderColumn } from './columns/SubscriptionsHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { getAllFunction } from '../../../../redux/actions/commonCrud'
import { useAppDispatch } from '../../../../hooks'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import { OrdersListSearchComponent } from '../components/header/SubscriptionsListSearch'
import PaginationComponent from '../../../../../_template/layout/components/pagination'
import { ActivitiesState, IUserSubscription } from '../../../../type'
import DocsCount from '../../../../components/DocsCount'
import { useIntl } from 'react-intl'
type Props = {
  // eventKey?: string | undefined
  setCount: any
}
type values = {
  role?: string | undefined
  phone?: string | undefined
  status?: string | undefined
  firstname?: string | undefined
  lastname?: string | undefined
  email?: string | undefined
}
const SubsctiptionsTable: React.FC<Props> = ({ setCount }) => {
  const orders: IUserSubscription[] = useSelector(({ activities }: { activities: ActivitiesState }) => {
    return activities.userSubscriptions
  })
  const [values, setValues] = useState<values | undefined>({})
  const [filtersValues, setFiltersValues] = useState<any>([])
  const intl = useIntl()
  const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }
  const isLoading = useQueryResponseLoading()
  const data: IUserSubscription[] = useMemo(() => orders, [orders])
  const columns = useMemo(() => SubscriptionsColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  const dispatch = useAppDispatch()
  const handleDispatch = () => {
    dispatch(
      getAllFunction(
        endpoints.listUsersSubscriptions,
        actionTypes.GET_ALL_USER_SUBSCRIPTIONS,
        filtersValues
      )
    )
  }
  const totalPages: number = useSelector(({ activities }: { activities: ActivitiesState }) => {
    return activities.totalPages
  })

  const totalItems: number = useSelector(({ activities }: { activities: ActivitiesState }) => {
    return activities.totalItems
  })

  useEffect(() => {
    if (totalItems) {
      setCount(totalItems)
    } else {
      setCount(0)
    }
  }, [totalItems])
  return (
    <KTCard>
      <KTCardBody className='py-4'>
        <div className='d-flex justify-content-between'>
          <div className='w-md-100'>
            <OrdersListSearchComponent
              values={values}
              handleChangeValues={handleChangeValues}
              handleDispatch={handleDispatch}
              //eventKey={eventKey}
              setFiltersValues={setFiltersValues}
              filtersValues={filtersValues}
            />
          </div>
        </div>
        <div className='d-flex' style={{ marginTop: "-2.8rem" }}>
          <DocsCount
            showNumbers={true}
            count={totalItems}

            title={intl.formatMessage({ id: "MENU.SUBSCRIPTIONS" })}
          />
        </div>

        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle fs-8 gy-5 dataTable no-footer '
            {...getTableProps()}
          >
            <thead style={{ backgroundColor: '#EFF0F4' }}>
              <tr className='text-bluee fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IUserSubscription>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-500 fs-8' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IUserSubscription>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <PaginationComponent
          values={values}
          setValues={setValues}
          endpoint={endpoints.listUsersSubscriptions}
          actionType={actionTypes.GET_ALL_USER_SUBSCRIPTIONS}
          totalPages={totalPages}
        />
        {/* {isLoading && <OrdersListLoading />} */}
      </KTCardBody>
    </KTCard>
  )
}

export { SubsctiptionsTable }
