import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../../_template/helpers'
import {useLang} from '../../../../../../_template/i18n/Metronici18n'
import Export from '../../../../../components/export'

const SubscriptionsListToolbar = () => {
  const locale: string = useLang()

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <Export dataType='orders' /> */}
    </div>
  )
}

export {SubscriptionsListToolbar}
