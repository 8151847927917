/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../_template/assets/ts/_utils'
import { useThemeMode } from '../../../_template/partials/layout/theme-mode/ThemeModeProvider'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IProfile, Series, StatisticsState, UsersState } from '../../type'

type Props = {
  className: string
}

const ChartsWidget8: React.FC<Props> = ({ className }) => {
  const series: any = useSelector(({ stats }: { stats: StatisticsState }) => {
    return stats.activities
  })
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()


  useEffect(() => {
    const refreshChart = () => {
      if (!chartRef.current) {
        return
      }

      const height = parseInt(getCSS(chartRef.current, 'height'))

      const chart = new ApexCharts(chartRef.current, getChartOptions(height, series))
      if (chart) {
        chart.render()
      }

      return chart
    }
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, series])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title  align-items-start flex-column text-bluee fs-6'>
          <FormattedMessage id='GORELLA.DASHBOARD.ANALYTICS' />

          {/* <span className='text-muted fw-semibold fs-7'>More than 500 new series</span> */}
        </h3>

        {/* begin::Toolbar */}
        {/* <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
            id='kt_charts_widget_8_year_btn'
          >
            Year
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_8_month_btn'
          >
            Month
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
            id='kt_charts_widget_8_week_btn'
          >
            Week
          </a>
        </div> */}
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div
          ref={chartRef}
          id='kt_charts_widget_8_chart'
          style={{ height: '350px' }}
          className='card-rounded-bottom'
        ></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { ChartsWidget8 }

function getChartOptions(height: number, series: { [day: string]: Series[] }): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-600')
  const borderColor = getCSSVariableValue('--bs-gray-400')
  const strokeColor = getCSSVariableValue('--bs-gray-500')
  const color1 = '#ADD8E6'
  const color1Light = '#b500f714'
  const color2 = '#FFA500'
  const color2Light = '#4756a93b'
  const color3 = '#D3D3D3'
  const color4 = '#336699'
  const color5 = '#FFA07A'
  const color6 = '#808080'
  const color3Light = '#87e2ff20'

  // Extract days from the series object keys
  const actionColors: { [action: string]: string } = {
    AddBg: color1,
    Rating: color2,
    DowndoadAddBg: color3,
    DowndoadRemoveBg: color4,
    EnhancedImage: color5,
    Resize: color6
  };
  const days = Object.keys(series);

  // Extract actions from the series object values
  const actions: string[] = Object.values(series)
    .reduce((acc: string[], val: Series[]) => acc.concat(val.map(item => item.name)), [])
  const uniqueActions: string[] = Array.from(new Set(actions));


  // Initialize data array for ApexCharts
  const data = uniqueActions.map(action => ({
    name: action,
    data: days.map(day => {
      const dayData = series[day].find(item => item.name === action);
      return dayData ? dayData.data[0] : 0;
    }),
    color: actionColors[action] || color3
  }));
  return {
    series: data,
    chart: {
      fontFamily: 'inherit',
      type: 'line',
      height: height,
      foreColor: '#ccc',
      toolbar: {
        autoSelected: 'pan',
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '100%',
      },

      bubble: {
        minBubbleRadius: 500,
        maxBubbleRadius: 500,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: '14px',
        fontWeight: 400,
        colors: [color1, color2, color3, '#0761b5'],
      },
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'straight',
      show: true,
      width: 2,
      colors: [color1, color2, color3, '#0761b5'],
    },
    xaxis: {
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisBorder: {
        show: true,
        color: '#78909C',
        // height: 1,
        // width: '100%',
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },

      crosshairs: {
        show: true,
        position: 'back',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        color: '#78909C',
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        show: true,

        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return `${val}`
        },
      },
    },
    colors: [color1Light, color2Light, "red", 'yellow'],
    grid: {
      borderColor: borderColor,
      //clipMarkers: false,
      strokeDashArray: 4,
      padding: {
        top: 0,
        bottom: 29,
        left: 20,
        right: 50,
      },

      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      size: 5,
      colors: ['#ffffff', '#ffffff', '#ffffff'],
      strokeColors: [color1, color2, color3, '#0761b5'],
      strokeWidth: 3,
    },
  }
}
