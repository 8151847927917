/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../helpers'
import {Link} from 'react-router-dom'
import {useLang} from '../../../i18n/Metronici18n'
import {useIntl} from 'react-intl'
import {useAppDispatch} from '../../../../app/hooks'
import {deleteFunction, getAllFunction} from '../../../../app/redux/actions/commonCrud'
import * as endpoints from '../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../app/redux/actionTypes'

type Props = {
  id: ID
  type: 'drivers' | 'users'
  useListView:any
}

const UserActionsCell: FC<Props> = ({id, type,useListView}) => {
  const {setItemIdForUpdate} = useListView()

  const locale: string = useLang()
  const intl = useIntl()

  // const location = useLocation()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // const openEditModal = () => {
  //   setItemIdForUpdate(id)
  // }
  const dispatch = useAppDispatch()
  // const deleteItem = useMutation(() => dispatch(deleteFunction(endpoints.userEndpoint, id)), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     dispatch(getAllFunction(endpoints.userEndpoint, actionTypes.GET_ALL_USERS, {}))
  //   },
  // })

  return (
    <>
      <a
        href='#'
        className='btn btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
      >
        {/* <KTSVG path='/media/icons/duotune/print/dots.svg' /> */}
        <img src="/media/icons/duotune/print/dots.png"  className='svg-icon-4 ml-5'/>{' '}
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fs-8 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link className='menu-link px-3 text-darkblue' to={`/${type}/${id}`}>
            {intl.formatMessage({id: 'GORELLA.COMMON.DETAILS'})}
          </Link>
        </div>
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            {intl.formatMessage({id: 'GORELLA.COMMON.EDIT'})}
          </a>
        </div> */}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            {intl.formatMessage({id: 'GORELLA.COMMON.DELETE'})}
          </a>
        </div> */}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {UserActionsCell}
