/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, FC} from 'react'
import {useIntl} from 'react-intl'
import {initialQueryState, KTSVG, useDebounce} from '../../../../../../_template/helpers'
import {useLang} from '../../../../../../_template/i18n/Metronici18n'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useSelector} from 'react-redux'
import {ConfigState, IUsers, UsersState} from '../../../../../type'
type values = {
  role?: string | undefined
  phone?: string | undefined
  status?: string | undefined
  firstname?: string | undefined
  lastname?: string | undefined
  email?: string | undefined
}
type Props = {
  eventKey: string | undefined
  handleDispatch: () => any
  values: values | undefined
  handleChangeValues: (e: any) => any
}

const RPListSearchComponent: FC<Props> = ({
  values,
  eventKey,
  handleChangeValues,
  handleDispatch,
}) => {
  const intl = useIntl()
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  const locale: string = useLang()
  const users: IUsers[] = useSelector(({users}: {users: UsersState}) => {
    return users.usersList
  })
  const activities = useSelector(({config}: {config: ConfigState}) => config.activities)

  return (
    <div className='card-title'>
      <div className='d-flex'>
        <div
          style={{
            position: 'relative',
          }}
          className='d-flex align-items-center position-relative my-4 w-100'
        >
          {' '}
          {/* 
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control px-14'

            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          /> */}
          <button
            data-kt-menu-trigger='click'
            data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
            type='button'
            style={{
              position: 'absolute',
              right: locale !== 'ar' ? 0 : undefined,
              left: locale === 'ar' ? 0 : undefined,
              border: 0,
              zIndex: 5,
            }}
            className='btn btn-icon '
          >
            <img
              src='/media/icons/duotune/print/Filter.png'
              className='svg-icon-8 position-absolute'
            />{' '}
          </button>
          {/* begin::SubMenu */}
          <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
            {/* begin::Header */}
            <div className='px-7 py-5'>
              <div className='fs-7 text-dark fw-bolder'>
                {intl.formatMessage({id: 'GORELLA.COMMON.FILTER_OPTIONS'})}
              </div>
            </div>
            {/* end::Header */}

            {/* begin::Separator */}

            <div className='d-flex justify-content-center'>
              <div className='separator border-gray-200' style={{width: '280px'}}></div>
            </div>

            {/* end::Separator */}

            {/* begin::Content */}
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label text-darkblue fs-8 fw-bold'>
                  {intl.formatMessage({id: 'GORELLA.COMMON.USER'})}
                </label>
                <select
                  className='form-select rounded form-select-solid fs-8'
                  data-kt-select2='true'
                  placeholder='user'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  name='user_id'
                  data-kt-user-table-filter='user'
                  data-hide-search='true'
                  onChange={handleChangeValues}
                  value={undefined}
                >
                  {/* <option value=''></option> */}
                  <option value=''>All</option>
                  {users.map((user) => (
                    <option value={user._id as any}>{user.firstname + ' ' + user.lastname}</option>
                  ))}
                </select>
              </div>
              {/* end::Input group */}
              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label text-darkblue fs-8 fw-bold'>
                  {intl.formatMessage({id: 'GORELLA.COMMON.ACTIONS'})}
                </label>
                <select
                  className='form-select rounded form-select-solid fs-8'
                  data-kt-select2='true'
                  placeholder='user'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  name='action'
                  data-kt-user-table-filter='user'
                  data-hide-search='true'
                  onChange={handleChangeValues}
                  value={undefined}
                >
                  {/* <option value=''></option> */}
                  <option value=''>All</option>
                  {activities.map((activity) => (
                    <option value={activity._id as any}>{activity.name}</option>
                  ))}
                </select>
              </div>
              {/* end::Input group */}

              {/* begin::Actions */}
              <div className='d-flex justify-content-end pt-15'>
                <button
                  // disabled={isLoading}
                  type='button'
                  onClick={() => handleDispatch()}
                  className='btn btn-design-blue btn-active-light-primary'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                >
                  {intl.formatMessage({id: 'GORELLA.COMMON.APPLY'})}
                </button>
              </div>
              {/* end::Actions */}
            </div>
            {/* end::Content */}
          </div>
          {/* end::SubMenu */}
        </div>
      </div>
      {/* end::Search */}
    </div>
  )
}

export {RPListSearchComponent}
