import {FC} from 'react'
import {UserActivitiesListWrapper} from './UserActivities-list/UsersActivitiesList'
const REACT_APP_ROLE_DRIVER = process.env.REACT_APP_ROLE_DRIVER
const DriversList: FC = () => {
  return (
    <>
      <UserActivitiesListWrapper />
    </>
  )
}

export default DriversList
