import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
// import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {checkResetTokenValidity, requestPassword, resetPassword} from '../core/_requests'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'

const initialValues = {
  password: '',
  confirmPassword: '',
}

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const intl = useIntl()
  const {email, token} = useParams()
  const [resetTokenStatus, setResetTokenStatus] = useState<
    'VALID' | 'ERROR' | 'LOADING' | 'SUCCESS'
  >('LOADING')

  useEffect(() => {
    checkResetTokenValidity(email, token)
      .then(({data}) => {
        if (data.code === 2100) {
         // console.log(data)
          setResetTokenStatus('VALID')
        }
      })
      .catch((err) => {
        setResetTokenStatus('ERROR')
      })
  }, [])


  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(50, intl.formatMessage({id: 'AUTH.GENERAL.MIN50'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.PASSWORD_IS_REQUIRED'})),
    confirmPassword: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(50, intl.formatMessage({id: 'AUTH.GENERAL.MIN50'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.NEWPASSWORD_IS_REQUIRED'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      if (values.password !== values.confirmPassword) {
        alert('Passwords must be the same')
        return
      }

      setLoading(true)
      setHasErrors(undefined)

      resetPassword(email, token, values.password)
        .then(() => {
          setResetTokenStatus('SUCCESS')
          setHasErrors(false)
          setLoading(false)
        })
        .catch((err) => {
          //console.log(err)

          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          setStatus('The login detail is incorrect')
        })
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      {resetTokenStatus === 'LOADING' && <>Loading...</>}
      {(resetTokenStatus === 'VALID' || resetTokenStatus === 'SUCCESS') && (
        <>
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder mb-3'>
              {intl.formatMessage({id: 'AUTH.RESETPASSWORD.MAIN_TITLE'})}
            </h1>
            {/* end::Title */}

            {/* begin::Link */}
            {/* <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({id: 'GORELLA.COMMON.MYPROFILE'})}.
        </div> */}
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {intl.formatMessage({id: 'AUTH.GENERAL.DETECTEDERRORS'})}
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>
                {intl.formatMessage({id: 'AUTH.GENERAL.PASSWORD_RESET_SUCCESS'})}
              </div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>
              {intl.formatMessage({id: 'AUTH.RESETPASSWORD.NEW_PASSWORD'})}
            </label>
            <input
              disabled={resetTokenStatus === 'SUCCESS'}
              type='text'
              placeholder={intl.formatMessage({id: 'AUTH.RESETPASSWORD.NEW_PASSWORD'})}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>
              {intl.formatMessage({id: 'AUTH.RESETPASSWORD.CONFIRM_NEW_PASSWORD'})}
            </label>
            <input
              disabled={resetTokenStatus === 'SUCCESS'}
              type='text'
              placeholder={intl.formatMessage({id: 'AUTH.RESETPASSWORD.CONFIRM_NEW_PASSWORD'})}
              autoComplete='off'
              {...formik.getFieldProps('confirmPassword')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
                {
                  'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              disabled={resetTokenStatus === 'SUCCESS'}
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-design-blue me-4'
            >
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              </span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            {/* <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '} */}
          </div>
          {/* end::Form group */}
        </>
      )}
      {resetTokenStatus === 'ERROR' && (
        <>
          <div className='d-flex text-error'>
            <h2 className='mx-auto text-center text-danger'>
              {intl.formatMessage({id: 'AUTH.GENERAL.ERROR_IN_RESETING_PASSWORD'})}
            </h2>
          </div>
        </>
      )}
    </form>
  )
}
