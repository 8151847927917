/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PageTitle } from '../../../_template/layout/core'
import {
  ChartsWidget5,
  ChartsWidget8,
  TablesWidget6,
  TablesWidget7,
  RoundedChart,
  StatisticsWidget2,
} from '../../../_template/partials/widgets'
import { ChartsWidget9 } from '../../../_template/partials/widgets/charts/chartWidget9'
import { useAppDispatch } from '../../hooks'
import * as endpoints from '../../../_template/helpers/endpoints'
import * as actionTypes from '../../redux/actionTypes'
import { getUserByCountry } from '../../redux/actions/statistics'
import { useSelector } from 'react-redux'
import { DashboardFilters } from './filters'
import { StatsByCountry } from './SubscriptionsStats'
import { StatisticsState } from '../../type'
//import { StatsByCountry } from './StatsByCountries'
const usersIcon = "/media/avatars/Mask.png"
const revnuIcon = "/media/avatars/Wallet.png"
const actionsIcon = "/media/avatars/totalaction.png"
const DashboardPage: FC = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const data: any[] = useSelector(({ stats }: { stats: StatisticsState }) => {
    return stats.data
  })

  useEffect(() => {
    dispatch(getUserByCountry(endpoints.UserByCountry, actionTypes.GET_ALL_STATISTICS, {}))
    dispatch(getUserByCountry(endpoints.ActionPerDay, actionTypes._PER_DAY, {}))
    dispatch(
      getUserByCountry(endpoints.SubscriptionRevenue, actionTypes.GET_ALL_SUBSCRIPTION_REVENU, {})
    )
  }, [dispatch])
  const { userPercentageByCountry, totalUsers, totalRevenu, totalActivites }: any = useSelector(
    ({ stats }: { stats: StatisticsState }) => {
      return stats
    }
  )

  const subscriptionPercentages: any = useSelector(({ stats }: { stats: StatisticsState }) => {
    return stats.subscriptionPercentages
  })
  const revenue: any = useSelector(({ stats }: { stats: StatisticsState }) => {
    return stats.revenue
  })
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8 ' style={{ marginTop: "-6rem" }}>
        <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-4 my-2' key={`statInfo_TOTAL_USERS`}>
          <StatisticsWidget2
            className='card-xl-stretch mb-xl-8'
            avatar={usersIcon}
            title={intl.formatMessage({ id: 'GORELLA.COMMON.TOTAL_USERS' })}
            value={totalUsers}
            percentage={'item.percentage'}
          />
        </div>
        <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-4 my-2' key={`statInfo_TOTAL_REVENU`}>
          <StatisticsWidget2
            className='card-xl-stretch mb-xl-8'
            avatar={revnuIcon}
            title={intl.formatMessage({ id: 'GORELLA.COMMON.TOTAL_REVENU' })}
            value={totalRevenu + " TND"}
            percentage={'item.percentage'}
          />
        </div>
        <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-4 my-2' key={`statInfo-TOTAL_ACTIONS`}>
          <StatisticsWidget2
            className='card-xl-stretch mb-xl-8'
            avatar={actionsIcon}
            title={intl.formatMessage({ id: 'GORELLA.COMMON.TOTAL_ACTIONS' })}
            value={totalActivites}
            percentage={'item.percentage'}
          />
        </div>
      </div>
      {/* end::Row */}
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {/* <div className='col-xl-6'>
          <ChartsWidget5 className='card-xl-stretch mb-xl-8' />
        </div> */}

        <div className='col-xl-6'>
          <RoundedChart
            className='card-xl-stretch mb-xl-5'
            chartColor='gray'
            chartHeight='260px'
            sales={userPercentageByCountry}
            title={<FormattedMessage id='GORELLA.DASHBOARD.USER_BY_COUNTRIES' />}

          />
          {/* <ChartsWidget9 className='card-xl-stretch mb-xl-8' /> */}
        </div>
        <div className='col-xl-6'>
          <RoundedChart

            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='300px'
            sales={subscriptionPercentages}
            title={<FormattedMessage id='GORELLA.DASHBOARD.DELIVERED' />}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xxl-6'>
          <StatsByCountry
            className='card-xl-stretch mb-xl-8'
            chartColor='#5E94FE'
            chartHeight='300px'
            sales={revenue}
            title={<FormattedMessage id='GORELLA.DASHBOARD.DELIVERED' />}
          />
        </div>
        {/* begin::Col */}
        <div className='col-xxl-6'>
          <ChartsWidget8 className='card-xl-stretch mb-5 mb-xl-8' />

          {/*<ChartsWidget9 className='card-xl-stretch mb-xl-8' /> */}
        </div>
      </div>
      {/* end::Row */}

      <div className='row g-5 gx-xxl-8'>
        {/* <div className='col-xl-12'>
          <TablesWidget7 className='card-xl-stretch mb-5 mb-xl-8' />
        </div> 
        {/* begin::Col */}
        {/* <div className='col-xl-4'>
          <TablesWidget6 className='card-xl-stretch mb-5 mb-xl-8' />
        </div> */}
        {/* end::Col */}
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className='d-flex justify-content-end overflow-hidden ' style={{ marginTop: '-6%', marginBottom: '25px' }}>
        <div style={{ color: 'red' }}>
          <PageTitle breadcrumbs={[]} >
            {intl.formatMessage({ id: 'MENU.OVERVIEW' })}
          </PageTitle>

        </div>
        <div className='mt-8 mb-20 '>
          <DashboardFilters />
        </div>
      </div>

      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
