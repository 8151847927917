import {FC} from 'react'
import moment from 'moment'
type Props = {
  date?: Date
  color?: string
}

const DateCell: FC<Props> = ({date, color}) => {
  // Moment.locale('en');
  // var dt = '2016-05-02T00:00:00';
  var CurrentDate = moment()
  return (
    <>
      {' '}
      {date && (
        <div className='fs-8' style={{color: color && CurrentDate.isAfter(date) ? 'red' : ''}}>
          {moment(date).format('MM.DD.YYYY')}{' '}
        </div>
      )}
    </>
  )
}

export {DateCell}
