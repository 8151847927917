import {useListView} from '../../core/ListViewProvider'
import {SubscriptionsListToolbar} from './SubscriptionsListToolbar'
import {SubscriptionsGrouping} from './SubscriptionsListGrouping'

const SubscriptionsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-1.2'>
      {/* <OrdersListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <SubscriptionsGrouping /> : <SubscriptionsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {SubscriptionsListHeader}
