import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {useLang} from '../../../../i18n/Metronici18n'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const locale: string = useLang()

  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const Bleu = '#1E4E9D'
  const Black ='#273240'

  return (
    <div className='menu-item' >
      <Link
        className={clsx(
          'menu-link without-sub fs-7',
          {active: isActive },
          `${locale === 'ar' ? 'rtl-border' : 'ltr-border'}`
        )} 
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <img className={clsx('bi fs-3')} alt='icon' src={fontIcon}></img>
          </span>
        )}
        {/* {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )} */}
<span className='menu-title' style={{color: isActive ? Bleu : Black}}>{title}</span>

      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
