// @ts-nocheck
import {Column} from 'react-table'
import {
  UserInfoCell,
  UserTwoStepsCell,
  UserActionsCell,
  UserSelectionCell,
  UserCustomHeader,
  UserSelectionHeader,
} from '../../../../../../_template/layout/components/columns'
// import {UserEmailCell} from '../../../../../../components/layout/components/columns/UserEmailCell'
// import {User} from '../../core/_models'
import {FormattedMessage} from 'react-intl'
import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'
import {useListView} from '../../core/ListViewProvider'
import {Link} from 'react-router-dom'

const usersColumns: ReadonlyArray<Column<IUsers>> = [
 
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
    
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index]._id} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.FULLNAME' />}
        className='min-w-125px'
      />
    ),
    id: 'fullname',
    Cell: ({...props}) => (
      <Link to={`/users/${props.data[props.row.index]._id}`}>
        <UserInfoCell user={props.data[props.row.index]} />
      </Link>
    ),
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title={<FormattedMessage id='GORELLA.COMMON.LAST_NAME'/>} className='min-w-125px' />,
  //   id: 'lastname',
  //   Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title={<FormattedMessage id='GORELLA.COMMON.COUNTRY'/>} className='min-w-125px' />,
    id: 'country',
    accessor: 'country',
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.EMAIL' />}
        className='min-w-80px'
      />
    ),
    accessor: 'email',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title={<FormattedMessage id='GORELLA.COMMON.USER_TYPE'/>} className='min-w-125px' />,
    id: 'typeUser',
    accessor: 'type',
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.SUBSCRIPTIONS' />}
        className='min-w-80px'
      />
    ),
    accessor: (row) => row.subscription_type_name || (row.subscription && row.subscription.subscription_type_name) || '',
    id: 'subscription_type_name'  
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='GORELLA.COMMON.UPDATE_RESIZE_NUMBER' />}
  //       className='min-w-80px'
  //     />
  //   ),
  //   accessor: 'resizeNumber',
  // },
  {
    Header: (props) => (
      
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GORELLA.COMMON.EXPIRYDATE' />}
        className='min-w-80px'
      />
    ),
    
    id: 'end_date',
    Cell: ({ ...props }) => {
      const subscriptionEndDate = props.data[props.row.index]?.subscription?.end_date;
      const endDate = props.data[props.row.index].end_date;
    
      return <DateCell color="true" date={subscriptionEndDate ?? endDate} />;
    },  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='GORELLA.COMMON.STATUS' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'status',
  //   Cell: ({...props}) => (
  //     <UserTwoStepsCell
  //       id={props.data[props.row.index]._id}
  //       two_steps={props.data[props.row.index].status}
  //     />
  //   ),
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        type='users'
        id={props.data[props.row.index]._id}
        useListView={useListView}
      />
    ),
  },
]

export {usersColumns}
