/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
// import {MessageView} from '../../../../components/layout/components/messageView'
import InfoView from '../../../../_template/layout/components/infoView'
import {fetcherror} from '../../../redux/actions/common'
// import {useDispatch} from 'react-redux'
import {useAppDispatch} from '../../../hooks'
import {setAuthToken} from '../core/dataAxios'
import {FormattedMessage, useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_template/helpers/AssetHelpers'
import {useLang} from '../../../../_template/i18n/Metronici18n'
import {KTSVG} from '../../../../_template/helpers'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: 'trustdevAdmin@trustdev.info',
  password: 'password123',
}
export function Login() {
  const [loading, setLoading] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const locale: string = useLang()
  const {saveAuth} = useAuth()
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)

        if (!auth.data?.access_token) {
          setSubmitting(false)
          setLoading(false)
          if (typeof auth.code === 'string' && auth.code === '2001') {
            dispatch(fetcherror(intl.formatMessage({id: 'GORELLA.LOGIN.WRONG.DETAILS'})))
          }
          if (typeof auth.code === 'string' && auth.code === '4004') {
            dispatch(fetcherror(intl.formatMessage({id: 'FAILED_MISSING_AUTORIZATION_HEADER'})))
          }
        } else if (auth.data?.role === 'ROLE_DRIVER') {
          dispatch(fetcherror(intl.formatMessage({id: 'ECOMMERCE.LOGIN.WRONG.DETAILS'})))
        } else {
          saveAuth(auth)
          setAuthToken(auth.data.access_token)
          localStorage.setItem('sessionId', auth.sessionId)
          //dispatch(getAllFunction(endpoints.allNotifications, actionTypes.GET_ALL_NOTIFICATIONS, {}))
        }
      } catch (error: any) {
        setSubmitting(false)
        setLoading(false)
        dispatch(fetcherror(intl.formatMessage({id: 'GORELLA.LOGIN.NOT.ALLOWED'})))
      }
    },
  })

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown)
  }
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
      style={{marginTop:'-4rem'}}
    >
      {/* begin::Heading */}
      <div className='text-center mb-6'>
        <div className='py-4'>
          <img
            className='w-20rem h-80px'
            src={toAbsoluteUrl('/media/logos/logo.png')}
            alt='logo'
            style={{marginRight:"35rem"}}
          />
        </div>{' '}
        {/* <h1 className='text-dark fw-bolder mb-3'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' />
        </h1> */}
        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <InfoView />
        <label className='form-label fs-7 fw-bolder '>
          <FormattedMessage id='AUTH.INPUT.EMAIL' />
        </label>
        <input
          placeholder={intl.formatMessage({id: 'ENTER.YOUR.EMAIL'})}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
          style={{

            borderRadius: '6px',
            width:'328px',
          }}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder  fs-7 mb-0'>
          <FormattedMessage id='AUTH.INPUT.PASSWORD' />
        </label>
        <div
          style={{
            position: 'relative',
            width:'358px'
          }}
          className='d-flex align-items-center position-relative my-1 '
          
        >
          <input
            type={passwordShown ? 'text' : 'password'}
            placeholder={intl.formatMessage({id: 'ENTER.YOUR.PASSWORD'})}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
            style={{

              borderRadius: '6px',
              width:'328px',
            }}
          />
          <button
            style={{
              position: 'absolute',
              right: locale !== 'ar' ? 30 : undefined,
              left: locale === 'ar' ? 30 : undefined,
              border: 0,
              zIndex: 5,
             
            }}
            data-kt-menu-trigger='click'
            data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
            type='button'
            onClick={togglePassword}
            // btn-light has been removed to match with the figma design
            className='btn btn-icon '
          >
            {passwordShown ? (
              <KTSVG path='/media/svg/icons/show.svg' className='svg-icon-3 position-absolute'      
 />
            ) : (
              <KTSVG path='/media/svg/icons/hide.svg' className='svg-icon-3 position-absolute' />
            )}{' '}
          </button>
        </div>



        
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-wrap gap-18 fs-7 fw-semibold mb-8'>
        <span style={{color: '#2B3D44'}}>
          <input className='me-2' type='checkbox' value='' id='flexCheckChecked' />
          <FormattedMessage id='AUTH.GENERAL.REMEMBER_ME' />
        </span>

        {/* begin::Link */}
        <Link to='/auth/forgot-password' style={{color: '#2B3D44'}}>
          <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn '
          style={{
            backgroundColor: '#1E4E9D',
            color: 'white',
            borderColor: '#000000',
            borderWidth: '0.1px',
            border: 'solid',
            borderRadius: '6px',
            width:'328px',
          }}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label fs-7'>
              <FormattedMessage id='AUTH.LOGIN.BUTTON' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              <FormattedMessage id='AUTH.INPUT.PLEASEWAIT' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
