import { PayloadAction } from '@reduxjs/toolkit'
import * as actionTypes from '../actionTypes'
import { CommonState } from '../../type'

const initialState: CommonState = {
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
}
export default function commonReducer(
  state = initialState,
  action: PayloadAction<string>
): CommonState {
  switch (action.type) {
    case actionTypes.FETCH_START: {
      return { ...state, error: '', message: '', loading: true }
    }
    case actionTypes.UPDATING_CONTENT: {
      return { ...state, error: '', message: '', updatingContent: true }
    }
    case actionTypes.FETCH_SUCCESS: {
      return {
        ...state,
        error: '',
        message: action.payload,
        loading: false,
        updatingContent: false,
      }
    }
    case actionTypes.SHOW_MESSAGE: {
      return {
        ...state,
        error: '',
        message: action.payload,
        loading: false,
        updatingContent: false,
      }
    }
    case actionTypes.FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: '',
        updatingContent: false,
      }
    }
    case actionTypes.HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
        updatingContent: false,
      }
    }
    case actionTypes.TOGGLE_APP_DRAWER: {
      return {
        ...state,
        isAppDrawerOpen: !state.isAppDrawerOpen,
      }
    }
    default:
      return state
  }
}
