import {FC, useState} from 'react'

import {useIntl} from 'react-intl'

type Props = {
  value?: string
  id: string
  type: 'user' | 'driver'
}
const RatingCell: FC<Props> = ({value, id, type}) => {
  const [rating, setRating] = useState(0)

  return (
    <div>
      <div className='star-rating'>
        {[...Array(5)].map((star, index) => {
          index += 1
          return (
            <span
              key={index}
              className={index <= rating ? 'on' : 'off'}
              onClick={() => setRating(index)}
            >
              <span style={{color:"#ffce00"}}>&#9733;</span>
            </span>
          )
        })}
      </div>
    </div>
  )
}

export {RatingCell}
